import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuthStore } from '@ui/store/authStore';
import { shouldRenewToken } from '@ui/store/authStore';
import { CustomerAccessTokenRenew } from '@client-shopify/gql/storefront/api/mutations';

export function useAuthRedirect() {
  const loggedIn = useAuthStore.getState().isLoggedIn;
  const router = useRouter();
  // Router logic for Authenticated Pages
  useEffect(() => {
    const redirectAccountPages = (url: string) => {
      // Redirect login and sign up routes to the account page if the user is logged in
      if (url.startsWith('/account/login') || url.startsWith('/account/signup')) {
        if (loggedIn) {
          router.push('/account');
        }
        // Redirect all other routes starting with `/account` to the login page if the user is not logged in
      } else if (
        url.startsWith('/account') &&
        !(url.startsWith('/account/reset') || url.startsWith('/account/activate')) &&
        !loggedIn
      ) {
        router.push('/account/login');
      }
      if (url.startsWith('/returns/login') && loggedIn) {
        router.push('/account/returns');
      }
      if (url.startsWith('/returns/account') && !loggedIn) {
        router.push('/returns/guest');
      }
    };
    const isExpired = shouldRenewToken();
    const token = useAuthStore.getState().token;
    const setToken = useAuthStore.getState().setToken;
    if (isExpired && token) {
      CustomerAccessTokenRenew({ customerAccessToken: token }).then((res) => {
        setToken(
          res.data?.customerAccessTokenRenew?.customerAccessToken?.accessToken ?? null,
          res.data?.customerAccessTokenRenew?.customerAccessToken?.expiresAt ?? null,
        );
      });
    }
    // Check if we need to redirect on first load.
    redirectAccountPages(router.pathname);

    // Check if we need to redirect on every route change
    router.events.on('routeChangeStart', redirectAccountPages);

    // Turn off the event listener on unmount
    return () => {
      router.events.off('routeChangeStart', redirectAccountPages);
    };
  }, [router, loggedIn]);
}
