import { LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import env from '@ui/env';
import { CartProduct } from '@ui/store/cartStore';
import { useQuery } from '@tanstack/react-query';
import CartUpsellCard from './CartUpsellCard';
import cn from '@ui/utils/cn';
import CartUpsellCardSkeleton from './CartUpsellCardSkeleton';
import { GetCollection, GetProducts } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import { ProductCollectionSortKeys } from '@client-shopify/gql/storefront/__generated__/graphql';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';
import { useEffect, useRef } from 'react';
import { Button } from '@ui/components/core/button';
import {
  BeaconProduct,
  createBeaconProfile,
  sendBeaconClickImpressionEvent,
  sendBeaconImpressionEvent,
  sendBeaconRenderEvent,
} from '@ui/axios/searchSpring/beacon';
import { useInView } from 'react-intersection-observer';

export type CartUpsellAddToCartItem = Omit<CartProduct, 'price'>;

type CartUpsellCarouselProps = {
  className?: string;
  controlClassName?: string;
  beaconPlacement: 'cart' | 'product-page';
};

const CartUpsellCarousel = ({ className,controlClassName, beaconPlacement }: CartUpsellCarouselProps) => {
  const carouselRef = useRef<SwiperRef>(null);
  const prev = useRef<HTMLButtonElement>(null);
  const next = useRef<HTMLButtonElement>(null);
  const { data: cartUpsellProducts, isLoading } = useQuery({
    queryKey: ['cartUpsellProducts'],
    queryFn: async () => {
      const result = await GetCollection({
        handle: 'promoted-products',
        sortKey: ProductCollectionSortKeys.Manual,
        first: 20,
      });
      const edges = result.data?.collection?.products.edges;
      if (!edges || edges.length === 0) return [];

      const products = edges.map((edge) => {
        const product = {
          imageUrl: edge.node.images.edges[0].node.url,
          title: edge.node.title,
          handle: edge.node.handle,
          price: edge.node.priceRange.maxVariantPrice.amount,
          currency: edge.node.priceRange.maxVariantPrice.currencyCode,
          id: edge.node.id.split('/').pop()!,
          sizes: edge.node.variants.edges.map((variant) => ({
            id: variant.node.id.split('/').pop()!,
            size: variant.node.title,
          })),
        };
        return product;
      });

      if (products.length > 0) {
        const productsFromShopify = await GetProducts({
          first: products.length,
          query: products.map((p) => `id:${p.id}`).join(' OR '),
          country: getCustomerCountry(),
        });

        products.forEach((product) => {
          const productEdges = productsFromShopify?.data?.products.edges || [];
          const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.id);
          if (!productNode) return;

          product.price = productNode.node.priceRange.maxVariantPrice.amount;
          product.currency = productNode.node.priceRange.maxVariantPrice.currencyCode;

          const images = productNode.node.images.edges.map((image) => image.node.url);
          product.imageUrl = images[0];
        });
      }

      return products;
    },
  });


  // Logic and code for beacon integration, searchspring
  const products:Array<BeaconProduct> = (cartUpsellProducts || []).map((product) => ({
      id: product.id,
    }))

  const beaconProfile = useRef(createBeaconProfile({ tag:"cart-upsell", placement:beaconPlacement }));

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) return;
    if (!products.length) return;

    isMounted.current = true;

    sendBeaconRenderEvent(beaconProfile.current, products);
  }, [products]);

  const { ref, entry } = useInView({ threshold: 1 });

  const hasEnteredViewport = useRef(false);

  const impressionsHistory = useRef<Array<string>>([]);

  if (!env.FEATURE_CART_UPSELL) {
    return null;
  }

  if (!isLoading && !cartUpsellProducts?.length) {
    return null;
  }

  return (
    <div ref={ref} className={cn("flex relative px-6 md:px-10 py-4",className)}>
      {isLoading ? (
        <SwiperCarousel parentRef={carouselRef} buttons={{
          prev,
          next
        }} 
        slidesPerView={1}
        spaceBetween={16}
        loop
        >
          <SwiperSlide>
            <CartUpsellCardSkeleton />
          </SwiperSlide>
        </SwiperCarousel>
      ):(
        <SwiperCarousel parentRef={carouselRef} buttons={{
          prev,
          next
        }} 
        slidesPerView={1}
        spaceBetween={16}
        loop
        onSlideInit={(swiper) => {
          if (hasEnteredViewport.current) return;
          if (entry?.isIntersecting) return; 
          const productInView = products[swiper.realIndex];
          if(!productInView) return;
          hasEnteredViewport.current = true;
          impressionsHistory.current.push(productInView.id);
          sendBeaconImpressionEvent(beaconProfile.current, [productInView]);
        }}
  
        onSlideChange={(swiper) => {
          const productInView = products[swiper.realIndex];
          if(!productInView) return;
          if(!impressionsHistory.current.includes(productInView.id)) {
            impressionsHistory.current.push(productInView.id);
            sendBeaconClickImpressionEvent(beaconProfile.current, [productInView]);
          }
        }}
        >
          {cartUpsellProducts?.map((cartUpsellProduct) => (
              <SwiperSlide id={cartUpsellProduct.id} key={cartUpsellProduct.id}>
                <div className='border-[0.6px] border-black bg-white p-3'>
                  <CartUpsellCard
                    cartUpsellProduct={cartUpsellProduct}
                    showAddIcon={beaconPlacement === 'product-page'}
                    swiperRef={carouselRef}
                  />
                </div>
              </SwiperSlide>
            ))
          }
        </SwiperCarousel>
      )}

      <div className={cn('absolute top-1/2 left-0 -translate-y-1/2 flex justify-between items-center pointer-events-none z-[10] w-full',controlClassName)} style={{
        height: 'calc(100% - 2rem)'
      }}>
        <Button ref={prev} variant='unstyled' 
          className='p-0 md:p-3 mr-4 pointer-events-auto transition duration-200 opacity-100 disabled:opacity-[0%] h-full'
        >
          <LeftChevronIcon height={20} width={20} />
        </Button>
        <Button ref={next} variant='unstyled' 
          className='p-0 md:p-3 ml-4 pointer-events-auto transition duration-200 opacity-100 disabled:opacity-[0%] h-full'

        >
          <RightChevronIcon height={20} width={20} />
        </Button>
      </div>
    </div>
  );
};

export default CartUpsellCarousel;
