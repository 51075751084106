import RunQuery from '@client-shopify/gql/utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_COLLECTIONS } from '../../documents/queries';
import type { GetCollectionsQuery } from '../../__generated__/graphql';

export type GetCollectionsArgs = {
  first: number;
};

export async function GetCollections({
  first,
}: GetCollectionsArgs): Promise<{ data: GetCollectionsQuery; error: CombinedError | undefined }> {
  const { data, error } = await RunQuery(GET_COLLECTIONS, { first: first });
  return { data, error };
}
