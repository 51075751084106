export const FilterImageCarouselMappings = [
  { 
    handle: "/collections/clothing",
    sectionTitle: 'Shop by Category',
    links: [
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/DRESSES_US.jpg?v=1730687260',
        text: 'Dresses',
        href: '/collections/dresses',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/PLAYSUITS_US.jpg?v=1730687261',
        text: 'Playsuits',
        href: '/collections/playsuits',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/TOPS_US.jpg?v=1730687261',
        text: 'Tops',
        href: '/collections/tops',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/BOTTOMS_US.jpg?v=1730687260',
        text: 'Bottoms',
        href: '/collections/bottoms',
      },
      {
        image:
          'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/SETS_AND_COORDS_US_5b6f6569-7359-46f0-a0b2-0a0eb16dbc43.jpg?v=1730932134',
        text: 'Sets & Co-ords',
        href: '/collections/sets',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/SWIM_US.jpg?v=1730687261',
        text: 'Swim',
        href: '/collections/swim',
      },
    ],
  },
  { 
    handle: "/cart",
    sectionTitle: 'Shop by Style',
    links: [
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/DRESSES_US.jpg?v=1730687260',
        text: 'Dresses',
        href: '/collections/dresses',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/PLAYSUITS_US.jpg?v=1730687261',
        text: 'Playsuits',
        href: '/collections/playsuits',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/TOPS_US.jpg?v=1730687261',
        text: 'Tops',
        href: '/collections/tops',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/BOTTOMS_US.jpg?v=1730687260',
        text: 'Bottoms',
        href: '/collections/bottoms',
      },
      {
        image:
          'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/SETS_AND_COORDS_US_5b6f6569-7359-46f0-a0b2-0a0eb16dbc43.jpg?v=1730932134',
        text: 'Sets & Co-ords',
        href: '/collections/sets',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/SWIM_US.jpg?v=1730687261',
        text: 'Swim',
        href: '/collections/swim',
      },
    ],
  }
];
