import { Menu } from '@mantine/core';
import { CountryCallingCode, CountryCode, getCountryCallingCode } from 'libphonenumber-js/mobile';
import React from 'react';
import DownChevronIcon from '../Icons/Common/DownChevronIcon';
import cn from '@ui/utils/cn';
import { Button } from "@ui/components/core/button";

const CountriesMapping: Array<{
  value: CountryCode;
  flag: string;
  callingCode: CountryCallingCode;
}> = [
  { value: 'US', flag: '🇺🇸', callingCode: getCountryCallingCode('US') },
  { value: 'CA', flag: '🇨🇦', callingCode: getCountryCallingCode('CA') },
  { value: 'AU', flag: '🇦🇺', callingCode: getCountryCallingCode('AU') },
  { value: 'NZ', flag: '🇳🇿', callingCode: getCountryCallingCode('NZ') },
  { value: 'GB', flag: '🇬🇧', callingCode: getCountryCallingCode('GB') },
];

type PhoneCountrySelectProps = Omit<React.ComponentProps<'button'>, 'onChange'> & {
  value: CountryCode;
  onChange?: (newValue: CountryCode) => void;
};

const PhoneCountrySelect = React.forwardRef<HTMLButtonElement, PhoneCountrySelectProps>(
  ({ value, onChange, className, ...props }, ref) => {
    const selectedCountry = CountriesMapping.find((c) => c.value === value);

    return (
      <Menu
        position="bottom-start"
        classNames={{
          dropdown: 'px-0 py-1 rounded-[4px] shadow-none border border-[#757575] bg-white',
          item: cn(
            'px-3 py-1 flex items-center text-[13px] rounded-none',
            'data-[hovered]:bg-white data-[hovered]:underline data-[hovered]:underline-offset-[6px]',
            'data-[selected]:bg-white data-[selected]:underline data-[selected]:underline-offset-[6px]',
          ),
        }}
      >
        <Menu.Target>
          <Button
            {...props}
            ref={ref}
            variant="unstyled"
            type="button"
            className={cn(
              'flex items-center space-x-2 rounded-[4px] px-3 mr-2.5 group no-underline',
              'border border-[#757575] bg-white',
              'focus:outline-none',
              className,
            )}
            aria-label='Select country'
          >
            <span className="text-xl">{selectedCountry?.flag || '🚫'}</span>
            <DownChevronIcon className="group-data-[expanded]:rotate-180 transition" width={16} height={16} />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {CountriesMapping.map((country) => (
            <Menu.Item
              className="group"
              key={country.value}
              onClick={() => onChange?.(country.value)}
              data-selected={country.value === value || undefined}
            >
              <span className="text-xl">{country.flag}</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>+{country.callingCode}</span>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  },
);

PhoneCountrySelect.displayName = 'PhoneCountrySelect';

export default PhoneCountrySelect;
