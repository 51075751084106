import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQTechnical = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.Technical.ChangingCurrency', title: 'Changing Currency' },
    { key: 'FAQ.Technical.ChangingEmail', title: 'Changing your email address' },
    { key: 'FAQ.Technical.EmailNotAccepted', title: 'Email address is not being accepted' },
    { key: 'FAQ.Technical.NotReceivingEmails', title: 'Not receiving emails' },
    { key: 'FAQ.Technical.OrderConfirmationEmails', title: 'Order confirmation emails' },
    { key: 'FAQ.Technical.LoginProblems', title: 'Trouble logging in' },
    { key: 'FAQ.Technical.Unsubscribe', title: 'Unsubscribe' },
  ];

  return (
    <FAQAccordion faqItems={faqItems} />
  );
};

export default FAQTechnical;