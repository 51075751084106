import {
  ContactDeliveryIcon,
  ContactReturnIcon,
  ContactStoreCreditIcon,
  ContactPayingIcon,
  PhoneIcon,
  EmailIcon,
} from '@ui/components/core';

export const ContactFAQLinks = [
  {
    title: 'Delivery',
    href: '/pages/faq/delivery',
    icon: ContactDeliveryIcon,
  },
  {
    title: 'Returns',
    href: '/pages/faq/returns-and-refunds/returns',
    icon: ContactReturnIcon,
  },
  {
    title: 'Store Credits',
    href: '/pages/faq/returns-and-refunds/store-credit',
    icon: ContactStoreCreditIcon,
  },
  {
    title: 'Paying',
    href: '/pages/faq/payments-and-promos',
    icon: ContactPayingIcon,
  },
];

export const ContactVia = [
  {
    title: 'Send us an email',
    description: `Tell us what you need help with and we&apos;ll get back to you ASAP!`,
    icon: EmailIcon,
    hasFormModal: true,
  },
  {
    title: 'Prefer to chat?',
    description: `Give us a call on <a className="underline" href="tel:+61290170300"> +61 2 9017 0300 </a>, Monday to Friday<br />9:30AM - 5:00PM AEST`,
    icon: PhoneIcon,
  },
];
