import { ServerProductProps } from '@ui/nextServer';
import { ProductTagMap } from '@ui/providers/static-content-provider';
import cn from '@ui/utils/cn';
import { unescape } from 'lodash';
import Link from 'next/link';

type ProductBrandFromTagsProps = {
  className?: string;
  tags: ServerProductProps['props']['product']['tags'];
  tagMapping?: Array<ProductTagMap>;
};

export default function ProductBrandFromTags({ className, tags, tagMapping }: ProductBrandFromTagsProps) {

  const brandAsTag = tags?.find((tag) => tag.startsWith('brand_'));
  
  if (!brandAsTag) return null;

  const mappedTag = tagMapping?.find((tag) => tag.tag === brandAsTag);

  const brandHandle = brandAsTag.split('_').slice(1).join(' ');
  const brand = unescape(brandHandle.replace(/-/g, ' '));

  return mappedTag ? 
    <Link className={cn('text-[10px] tracking-[0.55px] uppercase hover:underline', className)} href={mappedTag.url}>
      {brand}
    </Link> 
    : 
    <div className={cn('text-[10px] tracking-[0.55px] uppercase', className)}>{brand}</div>;
}
