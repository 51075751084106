import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { GetCustomer, GetCustomerOrders } from '@client-shopify/gql/storefront/api/queries';
import { useQuery } from '@tanstack/react-query';
import { setCookie } from '@ui/hooks/useCookie';
interface AuthState {
  token: string | null;
  expiresAt: string | null;
  customer: Awaited<ReturnType<typeof GetCustomer>>['data'];
  orders: Awaited<ReturnType<typeof GetCustomerOrders>>['data'] | [];
  isLoggedIn: boolean;
  customerId: string | undefined;
  setToken: (token: string | null, expiresAt?: string | null) => void;
  login: (token: string | null) => void;
  logout: () => void;
}

const initialState: Omit<AuthState, 'setToken' | 'login' | 'logout'> = {
  token: null,
  expiresAt: null,
  customer: {},
  orders: [] as Awaited<ReturnType<typeof GetCustomerOrders>>['data'] | [],
  isLoggedIn: false,
  customerId: undefined,
};

const initCustomerData = async (token: string | null) => {
  if (!token) {
    useAuthStore.setState({ ...initialState });
    return;
  }
  const customerRes = await GetCustomer({ customerAccessToken: token ?? '' });
  if (customerRes.error) {
    useAuthStore.setState({ ...initialState });
    return;
  }
  if (customerRes?.data?.customer?.id) {
    const customerId = customerRes.data.customer.id.split('/', -1).pop();
    setCookie('shopper', customerId);
    if (customerRes?.data?.customer?.id) {
      const customerId = customerRes.data.customer.id.split('/', -1).pop();
      useAuthStore.setState({
        isLoggedIn: true,
        customerId,
        customer: customerRes.data,
      });
    }
    useAuthStore.setState({
      isLoggedIn: true,
      customerId,
      customer: customerRes.data,
    });
  }
  const { data: orders } = await GetCustomerOrders({ customerAccessToken: token });
  useAuthStore.setState({ orders: orders ?? [] });
};

export const useAuthStore = create<AuthState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setToken: (token, expiresAt) => {
          set({
            token,
            expiresAt: expiresAt ?? null,
            isLoggedIn: token !== null && expiresAt !== null && new Date(String(expiresAt)) > new Date(),
          });
        },
        logout: () => {
          set({ ...initialState });
        },
        login: (token) => {
          set({ token });
        },
      }),
      {
        name: 'auth',
        onRehydrateStorage: () => (state) => {
          if (state?.token) {
            initCustomerData(state.token);
          }
        },
      },
    ),
  ),
);

useAuthStore.subscribe(
  (state) => state.token,
  (token) => {
    if (token) {
      initCustomerData(token);
    }
  },
);

export const useCustomer = () => {
  const token = useAuthStore.getState().token;
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['customer', token],
    queryFn: async () => {

      if (!token) {
        useAuthStore.setState({ ...initialState });
        return null;
      }
      const customerData = await GetCustomer({ customerAccessToken: token });
      if (customerData.error || !customerData.data?.customer) {
        useAuthStore.setState({ ...initialState });
        throw new Error('Authentication error');
      }
      useAuthStore.setState({
        isLoggedIn: true,
        customer: customerData.data,
        customerId: customerData.data.customer.id.split('/', -1).pop(),
      });
      return customerData.data.customer!;
    },
  });
  return { customer: data, isLoading, error, refetch };
};

export const shouldRenewToken = () => {
  const { token, expiresAt } = useAuthStore.getState();
  if (!token || !expiresAt) {
    return false;
  }
  const now = new Date();
  const expirationDate = new Date(expiresAt);

  // Calculate date 3 days from now
  const threeDaysFromNow = new Date();
  threeDaysFromNow.setDate(now.getDate() + 3);
  return expirationDate < threeDaysFromNow;
};
