import React from 'react';

const AccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.297 8.923a3.703 3.703 0 1 1 7.406 0 3.703 3.703 0 0 1-7.406 0ZM12 4.42a4.503 4.503 0 0 0-3.852 6.836c-3.709 1.781-4.296 5.844-4.372 7.628-.033.775.606 1.338 1.328 1.338h13.91c.69 0 1.308-.515 1.328-1.252.027-.961-.048-2.583-.682-4.161-.6-1.496-1.707-2.958-3.687-3.765A4.503 4.503 0 0 0 12 4.42Zm3.524 7.306a4.495 4.495 0 0 1-3.524 1.7 4.492 4.492 0 0 1-3.367-1.513c-3.397 1.54-3.982 5.23-4.058 7.005-.011.268.208.504.529.504h13.91c.305 0 .522-.217.529-.474.025-.908-.05-2.406-.626-3.841-.55-1.373-1.558-2.682-3.393-3.38Z" fill="currentColor"/>
  </svg>
);

AccountIcon.defaultProps = {
  onClick: undefined,
};

export default AccountIcon;
