import React from 'react';
import Marquee from 'react-fast-marquee';
import { AfterpayIcon, Group, KlarnaIcon, UnidaysIcon } from '@ui/components/core';
import { Text } from '@ui/components/shared/typography';
import env from '@ui/env';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';
import dynamic from 'next/dynamic';

type BannerProps = {
  hideCurrencyDropdown?: boolean;
};

const Banner = ({ hideCurrencyDropdown }: BannerProps) => {
  const paymentChannels = useStaticContent('WearNowPayLater.Channels');

  const CurrencyDropdown = dynamic(
    () => {
      return !hideCurrencyDropdown && !env.FEATURE_MULTICURRENCY
        ? import("@ui/components/features/global/BannerCurrencyDropdown/BannerCurrencyDropdown.tsx")
        : import("@ui/components/features/global/LocalCurrencyDropdown/LocalCurrencyDropdown.tsx");
    }
  );

  return (
    <div
      className="border-b-[0.6px] border-b-black bg-pink-3 flex items-center h-[--banner-height]"
      data-banner="usp-banner"
    >
      <section className="flex items-center px-4 md:px-8 h-full max-w-[1800px] w-full mx-auto">
        <div className="flex items-center space-x-4 w-full">
          
          <CurrencyDropdown className='shrink-unset h-6 min-w-20' />

          <div className="flex-1 overflow-hidden">
            <Marquee>
              <Group spacing="4rem" mr="4rem">
                {Array.from(Array(3).keys()).map((iteration) => (
                  <React.Fragment key={iteration}>
                    <Text sx={{ whiteSpace: 'nowrap' }}>
                      <StaticContent id="Banner.FreeShipping" inline />
                    </Text>
                    <Text sx={{ whiteSpace: 'nowrap' }}>
                      <b>100+ NEW ARRIVALS</b> EVERY WEEK!
                    </Text>
                    {env.FEATURE_UNIDAYS && (
                      <Group spacing="0.25rem" align="center" noWrap>
                        <Text sx={{ whiteSpace: 'nowrap' }}>STUDENTS GET 10% OFF WITH</Text>
                        <UnidaysIcon height={8.5} width={47.15} />
                      </Group>
                    )}
                    {(env.FEATURE_PRODUCT_BNPL || env.NEXT_PUBLIC_REGION === 'AU') && (
                      <Group spacing="0.25rem" align="center" noWrap>
                        <Text sx={{ whiteSpace: 'nowrap' }}>WEAR NOW. PAY LATER</Text>
                        {paymentChannels.includes('afterpay') && <AfterpayIcon height={10.5} width={51} />}
                        {paymentChannels.includes('klarna') && <KlarnaIcon height={9} width={41.53} />}
                      </Group>
                    )}
                  </React.Fragment>
                ))}
              </Group>
            </Marquee>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
