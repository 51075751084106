import cn from '@ui/utils/cn';
import { CircleCheckIcon } from '@ui/components/core';

const AddedToCart = ({ className, show, fill }: { className?: string; show: boolean; fill?: boolean }) => {
  return (
    <div
      className={cn(
        'absolute h-full w-full bg-black rounded-sm',
        'flex items-center justify-center',
        'text-[13px] font-normal text-center text-white',
        // "transition duration-200 ease-in",
        fill && 'inset-0',
        show ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full',
        className,
      )}
    >
      <span>ADDED TO BAG</span>
      <CircleCheckIcon width="16" height="16" className="ml-2" />
    </div>
  );
};

export default AddedToCart;
