export type ProductTagMap = {
  tag: string,
  url: string,
};

export const ProductTagMapping: ProductTagMap[] = [
  {
    tag: "brand_hello-molly-exclusive",
    url: "/collections/exclusive",
  },
  {
    tag: "brand_dear-emilia",
    url: "/collections/exclusive/brand_dear-emilia",
  },
  {
    tag:"brand_sur-belle",
    url: "/collections/sur-belle",
  },
  {
    tag:"brand_undercover-style-helpers",
    url: "/collections/accessories/brand_undercover-style-helpers",
  },
  {
    tag: "brand_wedding-parlour-by-hello-molly",
    url: "/collections/wedding-parlour",
  },
  {
    tag: "brand_lioness",
    url: "/collections/lioness-dress",
  }
];
