import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQOrderIssues = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.OrderIssues.GiftVouchersCancel', title: 'Cancelling gift vouchers' },
    { key: 'FAQ.OrderIssues.OrderCancel', title: 'Cancelling your order' },
    { key: 'FAQ.OrderIssues.OrderChange', title: 'Changing your order' },
    { key: 'FAQ.OrderIssues.Customs', title: 'Customs' },
    { key: 'FAQ.OrderIssues.IncorrectItem', title: 'Incorrect item in your order' },
    { key: 'FAQ.OrderIssues.MissingItem', title: 'Missing an item from your order' },
    { key: 'FAQ.OrderIssues.OrderVerification', title: 'Order verification and fraud checks' },
    { key: 'FAQ.OrderIssues.OutOfStock', title: 'Out of stock items post-order' },
    { key: 'FAQ.OrderIssues.FaultyItem', title: 'Received a faulty item' },
    { key: 'FAQ.OrderIssues.PriceDifferenceRefund', title: 'Refunding the price difference' },
    { key: 'FAQ.OrderIssues.ScamAndFraud', title: 'Scam websites & fraud' },
  ];

  return <FAQAccordion faqItems={faqItems} />;
};

export default FAQOrderIssues;