import React, { useEffect, useRef } from 'react';
import Link, { LinkProps } from 'next/link';
import cn from '@ui/utils/cn';
import Image from '@ui/components/core/image';

type ProductCardImageLinkProps = LinkProps & {
  className?: string;
  src: string;
  alt: string;
  height: number;
  width: number;
  productHandle: string;
  index?: number;
};

const ProductCardImageLink = ({
  className,
  src,
  alt,
  height,
  width,
  productHandle,
  index,
  ...props
}: ProductCardImageLinkProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    if (!isLoaded && imageRef.current?.complete) {
      setIsLoaded(true);
    }
  }, [imageRef.current?.complete]);
  return (
    <Link className={cn('block w-full relative pb-[150%]', className)} {...props} prefetch={false}>
      <picture
        className={cn(
          'size-[100%] absolute object-cover block transition-opacity duration-1000 ease-in',
          isLoaded ? 'opacity-100' : 'opacity-0',
        )}
      >
        <Image
          ref={imageRef}
          src={src}
          alt={alt}
          height={height}
          width={width}
          style={{ width: '100%' }}
          loading={index === 0 ? 'eager' : 'lazy'}
          fetchPriority={index === 0 ? 'high' : 'auto'}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      </picture>
    </Link>
  );
};

export default ProductCardImageLink;
