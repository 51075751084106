import RunBlogQuery from '@client-shopify/gql/utils/RunBlogQuery.ts';
import { GET_BLOG } from '../../documents/queries/GetBlog.ts';
import { type GetBlogQuery, type GetBlogQueryVariables } from '../../__generated__/graphql';
import { BlogReturnType } from '@client-shopify/gql/utils/GetBlogReturnType.type.ts';

export async function GetBlog({ handle, first, cursor, query }: GetBlogQueryVariables): Promise<BlogReturnType | null> {
  const { data, error } = await RunBlogQuery<GetBlogQuery>(GET_BLOG, {
    handle,
    first: first || 6,
    cursor: cursor || null,
    query,
  });

  if (!handle || !data?.blog) return null;

  const blog = {
    handle: data.blog.handle,
    title: data.blog.title,
    seo: {
      title: data.blog.seo?.title,
      description: data.blog.seo?.description,
    },
    articles: data.blog.articles.edges.map((articleEdge) => ({
      id: articleEdge.node.id,
      handle: articleEdge.node.handle,
      blog: { handle },
      title: articleEdge.node.title,
      publishedAt: articleEdge.node.publishedAt,
      excerpt: articleEdge.node.excerpt,
      content: articleEdge.node.content,
      image: {
        url: articleEdge.node.image?.url || null,
        altText: articleEdge.node.image?.altText || null,
      },
      seo: {
        title: articleEdge.node.seo?.title || null,
        description: articleEdge.node.seo?.description || null,
      },
    })),
  };

  const paginationCursor = data.blog.articles.edges[data?.blog?.articles.edges.length - 1].cursor;

  return { blog, paginationCursor, error };
}
