import React, { forwardRef } from 'react';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  fetchPriority?: 'auto' | 'high' | 'low' | 'none';
  loading?: 'eager' | 'lazy';
};

const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ loading = 'eager', fetchPriority = 'auto', ...props }, ref) => {
    return <img ref={ref} loading={loading} fetchPriority={fetchPriority} {...props} />;
  },
);

export default Image;
