import RunQuery from '@client-shopify/gql/utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_COLLECTION_FILTER } from '../../documents/queries';
import type { GetCollectionFilterQuery, GetCollectionFilterQueryVariables } from '../../__generated__/graphql';

export async function GetCollectionFilter({
  handle,
  filters,
  first,
  last,
  after,
  before,
  sortKey,
}: GetCollectionFilterQueryVariables): Promise<{ data: GetCollectionFilterQuery; error: CombinedError | undefined }> {
  const { data, error } = await RunQuery(GET_COLLECTION_FILTER, {
    handle: handle,
    filters: filters,
    first: first,
    last: last,
    after: after,
    before: before,
    sortKey: sortKey,
  });
  return { data, error };
}
