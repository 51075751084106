import React from 'react';
import Dialog from "@ui/components/core/dialog"


type FinalSaleModalProps = {
  opened: boolean;
  onClose: () => void;
};

const FinalSaleModal = ({ opened, onClose }: FinalSaleModalProps): React.ReactElement => {
  const body = (
    <>
      <div className="flex flex-row items-start justify-between pb-3 border-b border-neutral-grey-300 mb-4">
        <p className="uppercase text-base font-semibold">Final Sale Details</p>
      </div>
      <div className="mb-4 text-base/[160%]">
        <p className="mb-5">Please note items marked FINAL SALE cannot be returned or exchanged unless faulty.</p>
        <p>
          This applies to all products marked FINAL SALE and can include products listed with a sale price, intimates,
          earrings, and more.{' '}
          <span className="sm:block sm:mt-5">
            Please see our{' '}
            <a href="/pages/returns" className="underline">
              Returns Policy{' '}
            </a>
            for more details.
          </span>
        </p>
      </div>
    </>
  );

  return (
    <Dialog.Root open={opened} onOpenChange={onClose}>
      <Dialog.Content className='items-center rounded-lg p-4 pb-6 sm:p-8 sm:pb-8'
        classNames={{
          close: 'top-3 right-3 md:top-5 md:right-5',
        }}
        aria-describedby='final-sale-dialog'
      >
        {body}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default FinalSaleModal;
