import {
  AccountIcon,
  CompactCloseIcon,
  CompactRightChevronIcon,
  LeftArrowIcon,
  AccountHelpCentreIcon,
  AccountMyOrdersIcon,
} from '@ui/components/core';
import { Button } from "@ui/components/core/button"
import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader } from '@ui/components/core/drawer';
import Link from 'next/link';
import cn from '@ui/utils/cn';
import { useStaticContent } from '@ui/providers/static-content-provider';
import type { MenuLink } from '@ui/providers/static-content-provider';
import env from '@ui/env';
import { useEffect, useState } from 'react';
import { ScrollArea } from '@ui/components/core/scroll-area';
import { useTailwindMedia } from '@ui/hooks/useTailwindMedia';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

type MegaMenuDrawerProps = {
  isGuest: boolean;
  opened: boolean;
  onClose: () => void;
};

type MenuLinkItem = {
  label: string;
  href?: string;
  children?: number;
};

const MegaMenuDrawer = ({ isGuest, opened, onClose }: MegaMenuDrawerProps) => {
  const mobileMenuLinks = useStaticContent('Menu.MobileMenuLinkTree');
  const router = useRouter();
  const {isLg} = useTailwindMedia();
  const MenuLinkTable = mobileMenuLinks.reduce(
    (previousValue, currentLink) => {
      const newValue = { ...previousValue };

      const flattenLinks = (menuLink: MenuLink, parent: string) => {
        newValue[parent] ??= [];

        newValue[parent].push({
          label: menuLink.label,
          href: menuLink.href,
          children: menuLink.children?.length,
        });

        menuLink.children?.forEach((childLink) => {
          flattenLinks(childLink, `${parent}.${menuLink.label}`);
        });
      };

      flattenLinks(currentLink, '*');

      return newValue;
    },
    {} as Record<string, MenuLinkItem[]>,
  );

  const CurrencyDropdown = dynamic(
    () => {
      return !env.FEATURE_MULTICURRENCY
        ? import("@ui/components/features/global/BannerCurrencyDropdown/MobileCurrencyDropdown.tsx")
        : import("@ui/components/features/global/LocalCurrencyDropdown/MobileLocalCurrencyDropdown.tsx");
    }
  );

  const menuPaths = MenuLinkTable;
  const [activePaths, setActivePaths] = useState<string[]>(['*']);
  const [currentPath, setCurrentPath] = useState<string>('*');

  useEffect(() => {
    if (isLg) {
      onClose()
    }
  }, [isLg]);

  const closeFunction = () => {
    setActivePaths(['*']);
    setCurrentPath('*');
    onClose()
  }

  const goBack = (activePath:string) => {
    setCurrentPath(activePath.split('.').slice(0, -1).join('.'))
    setTimeout(() => {
      setActivePaths(activePaths.slice(0, -1))
    }, 100)
  }

  const goForward = (activePath:string,itemLabel:string) => {
    const newPath = `${activePath}.${itemLabel}`;
    const newActivePaths = activePaths
      .filter((path) => path.split('.').length < newPath.split('.').length)
      .map((path) => path);

    newActivePaths.push(newPath);

    setActivePaths(newActivePaths);
    setCurrentPath(newPath)
  }

  useEffect(() => {
    router.events.on('routeChangeStart', closeFunction);
    return () => router.events.off('routeChangeStart', closeFunction);
  }, [router.events, closeFunction]);

  return (
    <Drawer onClose={closeFunction} open={opened} direction="left">
      <DrawerContent side="left" className="w-full max-w-[280px]">
        <DrawerHeader className="relative">
          <h2 className="sr-only">Navigation</h2>
          <Button
            onClick={closeFunction}
            variant="unstyled"
            className={cn("absolute right-[-3.5rem] top-4 size-10 bg-white rounded-[4px] border-[0.6px] border-black flex items-center justify-center transition-opacity",opened ? 'opacity-100' : 'opacity-0 pointer-events-none')}
          >
            <CompactCloseIcon width={10} height={10} />
          </Button>
        </DrawerHeader>
        <DrawerBody scrollArea={false}>
          <div className="relative h-full w-[279px] overflow-x-hidden">
            {activePaths.map((activePath, index) => {
              return (
                <div
                  key={`${activePath}_${index}`}
                  className={cn(
                    'absolute top-0 left-0 w-full h-full bg-white transition-all duration-300',
                    activePath === currentPath
                      ? 'translate-x-0 opacity-1'
                      : activePath.length < currentPath.length
                        ? '-translate-x-full'
                        : 'translate-x-full opacity-0',
                    activePath !== '*' && 'animate-slideInRight pt-4',
                  )}
                >
                  {!!(activePath !== '*') && (
                    <div className="flex items-center justify-center h-10 w-full relative pt-4 px-4 pb-4 relative">
                      <Button
                        variant="unstyled"
                        className="flex items-center justify-center p-4 absolute left-0 top-1/2 -translate-y-1/2"
                        onClick={() => {
                          goBack(activePath);
                        }}
                      >
                        <LeftArrowIcon width={16} height={16} />
                      </Button>
                      <div className="text-[13px] tracking-[0.04em]">{activePath.split('.').pop()?.toUpperCase()}</div>
                      <div className="absolute top-[100%] left-0 w-full bg-gradient-to-b from-white to-transparent h-6 z-[1]"></div>
                    </div>
                  )}
                  <ScrollArea className={cn('flex-1 overflow-y-auto', activePath !== '*' && 'h-[calc(100%-40px)]')}>
                    <ul className={cn('space-y-3 p-4 pb-4')}>
                      {menuPaths[activePath].map((item, idx) => (
                        <li key={idx}>
                          {item.children ? (
                            <Button
                              key={item.label}
                              variant="unstyled"
                              className="w-full flex items-center justify-between text-left p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-10 no-underline"
                              onClick={() => {
                                goForward(activePath, item.label);
                              }}
                            >
                              <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{item.label}</span>
                              <CompactRightChevronIcon width={12} height={12} />
                            </Button>
                          ) : (
                            <Button
                              asChild
                              className="w-full flex items-center justify-between text-left text-black font-normal p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-10 no-underline"
                            >
                              <Link href={item.href || '#'}>
                                <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{item.label}</span>
                              </Link>
                            </Button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>
                </div>
              );
            })}
          </div>
        </DrawerBody>
        <DrawerFooter
          className="w-[279px] mt-0 relative overflow-visible
        "
        >
          <div className="absolute bottom-[100%] left-0 w-full bg-gradient-to-b from-transparent to-white h-6"></div>
          {currentPath === '*' ? (
            <div
              className={cn(
                'flex items-center justify-between',
                'p-4 bg-white',
                'animate-fadeIn',
                isGuest && 'gap-x-4',
              )}
            >
              {isGuest ? (
                <>
                  <Button
                    className={cn('!text-[10px] h-[38px]', env.FEATURE_MULTICURRENCY ? 'w-[70%]' : 'w-[60%]')}
                    asChild
                  >
                    <Link href="/account/login/"> LOGIN / SIGN UP</Link>
                  </Button>

                  <CurrencyDropdown
                    className={cn(
                      'border-[0.5px] border-black p-2 rounded-lg shadow-none overflow-none min-h-[40px] px-2',
                      !!env.FEATURE_MULTICURRENCY ? 'w-[30%]' : 'w-[40%]',
                    )}
                    dropdownClass="-translate-y-3"
                  />
                </>
              ) : (
                <>
                  <Link href="/account/" className="flex flex-col gap-y-1 items-center">
                    <Button className="py-[0.44rem] px-[1.27rem]">
                      <AccountIcon width={24} height={24} className="text-white" />
                    </Button>
                    <span className="text-[0.625rem]">Account</span>
                  </Link>
                  <Link href="/account/orders/" className="flex flex-col gap-y-1 items-center">
                    <Button className="py-[0.44rem] px-[1.27rem]">
                      <AccountMyOrdersIcon width={24} height={24} className="text-white" />
                    </Button>
                    <span className="text-[0.625rem]">My Orders</span>
                  </Link>
                  <Link href="/pages/contact-us/" className="flex flex-col gap-y-1 items-center">
                    <Button className="py-[0.44rem] px-[1.27rem]">
                      <AccountHelpCentreIcon width={24} height={24} className="text-white" />
                    </Button>
                    <span className="text-[0.625rem]">Help Centre</span>
                  </Link>
                </>
              )}
            </div>
          ) : null}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default MegaMenuDrawer;
