import React, { useRef, useState } from 'react';
import { LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import ProductCardImageLink from './ProductCardImageLink';
import cn from '@ui/utils/cn';
import CarouselIndicators from '../../carousels/CarouselIndicators/CarouselIndicators';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';
import { Button } from '@ui/components/core/button';

type ProductCardImageCarousel = {
  imageHeight: number;
  imageWidth: number;
  onImageClick?: React.MouseEventHandler<HTMLAnchorElement>;
  productHandle: string;
  productImages: Array<string>;
  productUrl: string;
  className?: string;
  draggable?: boolean;
};

export default function ProductCardImageCarousel({
  imageHeight,
  imageWidth,
  onImageClick,
  productHandle,
  productImages,
  productUrl,
  className,
  draggable,
}: ProductCardImageCarousel) {
  const swiperRef = useRef<SwiperRef>(null);
  const prev = useRef<HTMLButtonElement>(null);
  const next = useRef<HTMLButtonElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className={cn('relative', className)}>
      <div className="flex relative">
        <SwiperCarousel
          parentRef={swiperRef}
          onSlideInit={(swiper) => {
            setActiveSlide(swiper.realIndex);
          }}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.realIndex);
          }}
          buttons={{
            prev,
            next,
          }}
          allowTouchMove={draggable}
        >
          {productImages.map((image, index) => {
            return (
              <SwiperSlide key={`${productHandle}_${index}`}>
                <div className="relative">
                  <ProductCardImageLink
                    href={productUrl}
                    src={image}
                    alt={`${productHandle}-product-image`}
                    height={imageHeight}
                    width={imageWidth}
                    onClick={onImageClick}
                    productHandle={productHandle}
                    index={index}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </SwiperCarousel>
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full hidden md:flex justify-between items-center pointer-events-none z-[10]">
        <Button
          ref={prev}
          variant="unstyled"
          className={cn(
            'p-4 mr-4 pointer-events-none transition duration-200 opacity-0 ',
            'group-hover:opacity-100 group-hover:pointer-events-auto group-hover:disabled:opacity-[0%]',
          )}
        >
          <LeftChevronIcon height={20} width={20} />
        </Button>
        <Button
          ref={next}
          variant="unstyled"
          className={cn(
            'p-4 ml-4 pointer-events-none transition duration-200 opacity-0',
            'group-hover:opacity-100 group-hover:pointer-events-auto group-hover:disabled:opacity-[0%]',
          )}
        >
          <RightChevronIcon height={20} width={20} />
        </Button>
      </div>
      <CarouselIndicators
        className={cn(draggable === false && 'hidden', 'absolute bottom-2 md:opacity-0')}
        activeSlide={activeSlide}
        totalSlides={productImages.length}
      />
    </div>
  );
}
