import React from 'react';
import { Prose } from '@ui/components/core';
import { StaticContent } from '@ui/providers';
import { StaticContentType, useStaticContent } from '@ui/providers/static-content-provider';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@ui/components/core/accordion';


export type FAQItem = {
  key: string;
  title: string;
}

type FAQAccordionProps = {
  faqItems: FAQItem[];
}

const FAQAccordion:React.FC<FAQAccordionProps> = ({faqItems}) => {
  return (
    <Accordion type='single' collapsible>
    {faqItems.map((item, index) => (
      useStaticContent(item.key as keyof StaticContentType) && (
        <AccordionItem
          key={item.key}
          value={item.key.split('.').pop() || ""}
          className={index === faqItems.length - 1 ? 'border-b-0' : 'border-b-[0.6px] border-black'}
        >
          <AccordionTrigger className="uppercase text-sm py-[26px] text-left">
            {item.title}
          </AccordionTrigger>
          <AccordionContent>
            <Prose size="small">
              <StaticContent id={item.key as keyof StaticContentType} />
            </Prose>
          </AccordionContent>
        </AccordionItem>
      )
    ))}
  </Accordion>
  );
};

export default FAQAccordion;
