import Link from 'next/link';
import React from 'react';
import ProductCard, { ProductCardProducts } from '../../cards/ProductCard/ProductCard';
import { v4 as uuidv4 } from 'uuid';
import beacon from '@ui/axios/searchSpring/beacon';
import cn from '@ui/utils/cn';
import { getCookie, setCookie } from '@ui/hooks/useCookie';
import { useInView } from 'react-intersection-observer';

type ProductGridProps = {
  className?: string;
  title: string;
  viewAllLink: string;
  products: ProductCardProducts[];
  trackingDisabled?: boolean;
  searchSpringTag?: string;
  searchSpringPlacement?: string;
};

const ProductGrid = ({
  className,
  title,
  viewAllLink,
  products,
  trackingDisabled,
  searchSpringTag = '',
  searchSpringPlacement = '',
}: ProductGridProps) => {
  const mounted = React.useRef(false);
  const { ref, entry } = useInView({ threshold: 1 });

  React.useEffect(() => {
    if (trackingDisabled) return;

    if (mounted.current) return;

    if (!entry?.isIntersecting) return;

    if (!products.length) return;

    mounted.current = true;

    async function track() {
      try {
        const id = uuidv4();

        await beacon({
          id,
          placement: searchSpringPlacement,
          tag: searchSpringTag,
          type: 'profile.render',
        });

        await beacon({
          id,
          placement: searchSpringPlacement,
          tag: searchSpringTag,
          type: 'profile.impression',
        });

        await beacon({
          id: uuidv4(),
          pid: id,
          placement: searchSpringPlacement,
          skus: products.map((product) => product.sku),
          tag: searchSpringTag,
          type: 'profile.product.render',
        });

        await beacon({
          id: uuidv4(),
          pid: id,
          placement: searchSpringPlacement,
          skus: products.map((product) => product.sku),
          tag: searchSpringTag,
          type: 'profile.product.impression',
        });
      } catch {
        //
      }
    }

    track();
  }, [entry, products, trackingDisabled, searchSpringPlacement, searchSpringTag]);

  const storeToRecentSearches = () => {
    const linkArray = viewAllLink.split('/');
    const currentSearch = linkArray[linkArray.length - 1];
    const recentSearches = getCookie('recentSearches')
      .split(',')
      .filter((item) => item !== '')
      .toSpliced(7)
      .toString();
    setCookie('recentSearches', currentSearch + ',' + recentSearches);
  };

  return (
    <div ref={ref} className={cn('space-y-4', className)}>
      <div className="flex items-end justify-between">
        <div className="text-[13px] tracking-[0.55px] flex-1 font-bold uppercase">{title}</div>
        <Link
          className="text-[13px] tracking-[0.04em] uppercase whitespace-nowrap"
          href={viewAllLink}
          onClick={storeToRecentSearches}
        >
          View all
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-[18px]">
        {products.map((product) => (
          <div key={product.handle} className="pb-3 md:pb-0">
            <ProductCard
              layout="product"
              href={`/products/${product.handle}`}
              product={product}
              height={315}
              width={210}
              onClick={storeToRecentSearches}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
