import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQPopularQuestions = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.PopularQuestions.Collaboration', title: 'Can I collaborate with Hello Molly on Social Media?' },
    { key: 'FAQ.PopularQuestions.PhysicalStore', title: 'Do you have a store I can visit?' },
    { key: 'FAQ.PopularQuestions.StoreCredit', title: 'How can I access my store credit?' },
    { key: 'FAQ.PopularQuestions.ShippingSchedule', title: 'How long will it take for me to receive my order?' },
    { key: 'FAQ.PopularQuestions.MissingConfirmationEmail', title: 'I didn\'t receive an order or shipping confirmation. What should I do?' },
    { key: 'FAQ.PopularQuestions.BusinessHours', title: 'I want to call you. What are your working hours?' },
    { key: 'FAQ.PopularQuestions.StoreLocation', title: 'Where are you located?' },
    { key: 'FAQ.PopularQuestions.ReturnAddress', title: 'Where do I send returns?' },
  ];

  return <FAQAccordion faqItems={faqItems} />;
};

export default FAQPopularQuestions;