import { OperationResult, cacheExchange, Client, fetchExchange } from '@urql/core';
import { DocumentNode, print } from 'graphql';
import fetch from 'cross-fetch';
import env from '../../env.ts';

const RunBlogQuery = async <T = any>(query: DocumentNode, variables: object): Promise<OperationResult<T, object>> => {
  const endpoint = `https://${env.NEXT_PUBLIC_BLOG_ACCESS_ENDPOINT}/api/${env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION}/graphql.json`;
  const token = env.NEXT_PUBLIC_BLOG_ACCESS_TOKEN;

  const graphQLClient = new Client({
    url: endpoint,
    requestPolicy: 'cache-and-network',
    exchanges: [cacheExchange, fetchExchange],
    fetch: fetch,
    fetchOptions: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
      },
      body: JSON.stringify({ query: print(query), variables }),
    },
  });

  return await graphQLClient.query(query, variables).toPromise();
};

export default RunBlogQuery;
