import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_DEFAULT_ADDRESS_UPDATE } from '../../documents/mutations';
import type {
  CustomerDefaultAddressUpdateMutation,
  CustomerDefaultAddressUpdateMutationVariables,
} from '../../__generated__/graphql';

export async function CustomerDefaultAddressUpdate({
  customerAccessToken,
  addressId,
}: CustomerDefaultAddressUpdateMutationVariables): Promise<{
  data: CustomerDefaultAddressUpdateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE, {
    customerAccessToken: customerAccessToken,
    addressId: addressId,
  });

  return { data, error };
}
