import React from 'react';
import { createContext, ReactNode, useContext, SVGProps } from 'react';

export type Image = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

export type Video = {
  src: string;
  poster: Image;
};

export type ImageLink = Image & {
  label?: string;
  href: string;
};

export type GiftCardTemplate = {
  label: string;
  code: string;
  color: string;
  image_url: string;
  preview_url: string;
  icon_url: string;
};

export type GiftCardDenomination = {
  variantId: string;
  value: string;
};

export type MenuLink = {
  label: string;
  href?: string;
  column?: number;
  children?: Array<MenuLink>;
  upsell?: Array<{
    imageUrl: string;
    productUrl: string;
    productTitle: string;
  }>;
};

export type MegaMenuHeaderDropdownHeadingLabels = {
  [key: string]: Array<string>;
};

export type CollectionFilterMappingType = {
  [key: string]: {
    [key: string]: { label: string; icon: (props: SVGProps<SVGSVGElement>) => JSX.Element; href: string };
  };
};

export type Link = {
  title: string;
  href: string;
};

export type LinkWithIcon = {
  title: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export type FAQMenuMapping = {
  name: string;
  handle: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  content: () => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export type Region = { value: string; label: string };

export type ContactFAQLink = {
  title: string;
  href: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

export type ContactViaItem = {
  title: string;
  description: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  hasFormModal?: boolean;
};

export type FilterImageCarouselLink = {
  image: string;
  text: string;
  href: string;
};

export type FilterImageCarouselMappings = Array<{
  handle: string;
  sectionTitle: string;
  links: FilterImageCarouselLink[];
}>

export type ProductTagMap = {
  tag: string,
  url: string,
};

/*
  FYI: Link Object Subject to change when CMS is a go ahead, 
  will need to build it in a way to have internal object linking and external URL Linking
*/
export type LinkObject = {
  label: string;
  link: string;
  external?: boolean;
}

export type StaticContentType = {
  'HTML.lang': string;
  'Tag.YotpoPixel'?: string;
  'Tag.YotpoDataLayer'?: string;
  'Tag.YotpoConfig'?: string;
  'Tag.ElevarConfig'?: string;
  'Tag.ElevarDataLayer'?: string;
  'Tag.ElevarGTMSetup'?: string;
  'Tag.GTM.Linker.Domain': string;
  'Tag.Hotjar.ID'?: string;
  'Forter.Script'?: string;
  'Tag.GTM.ID': string;
  'Seo.Home.canonical': string;
  'Seo.Home.url': string;
  'Banner.FreeShipping': string;
  'Terms.Website': string;
  'Shipping.FreeShipping': string;
  'Shipping.ShippingStatus': string;
  'Shipping.DeliveryTime': string;
  'Shipping.TaxesAndDuties': string;
  'Shipping.Regions': Array<Region>;
  'Shipping.auNextDayBody'?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.auBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.nzBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.usBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.caBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.gbBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.apacBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Shipping.otherBody': React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  'Product.FreeShipping': string;
  'Product.DeliveryAndReturns': string;
  'Product.TagCollections': Array<ProductTagMap>;
  'Home.HeroBannerUrl': string;
  'Home.HeroBannerImageDesktop': Image;
  'Home.HeroBannerImageMobile': Image;
  'Home.HeroBannerVideoMobile'?: Video;
  'Home.HeroBannerVideoDesktop'?: Video;
  'Home.FeaturedCategories': Array<ImageLink>;
  'Home.MiddleBannerDesktop': ImageLink;
  'Home.MiddleBannerMobile': ImageLink;
  'Home.HeroVideoMobile': string;
  'Home.HeroVideoDesktop': string;
  'Menu.MegaMenuHeadingLabels': Array<string>;
  'Menu.MegaMenuHeaderDropdownHeadingLabels': { [key: string]: Array<string> };
  'Menu.MegaMenuDropdownLabels': { [key: string]: Array<Array<string>> };
  'Menu.DesktopMenuLinkTree': MenuLink[];
  'Menu.MobileMenuLinkTree': MenuLink[];
  'Menu.CategoryFilterMapping': { [key: string]: string[] };
  'Menu.MegaMenuRoutes': { [key: string]: string };
  'Campaign.EOFY.HeroBannerUrl': string;
  'Campaign.EOFYWeek1.HeroBannerImageDesktop': Image;
  'Campaign.EOFYWeek1.HeroBannerImageMobile': Image;
  'Campaign.EOFYWeek2.HeroBannerImageDesktop': Image;
  'Campaign.EOFYWeek2.HeroBannerImageMobile': Image;
  'GiftCard.Templates': Array<GiftCardTemplate>;
  'GiftCard.Denominations': Array<GiftCardDenomination>;
  'WearNowPayLater.Channels': Array<string>;
  'WearNowPayLater.Requirements': Array<string>;
  'WearNowPayLater.ExampleImageDesktop': Image;
  'WearNowPayLater.ExampleImageMobile': Image;
  'Collection.FilterImageCarouselMappings': FilterImageCarouselMappings;
  'Collection.BestSellerId': number;
  'Collection.CollectionFilterMapping': CollectionFilterMappingType;
  'Collection.ParentCollectionAssociations': { [key: string]: string };
  'Tag.Emarsys.WebExtend'?: string;
  'Tag.emarsysInnerScarabScriptFunc'?: string;
  'Tag.TikTokTrack'?: string;
  'Yotpo.SmsBumpAccount': string;
  'Yotpo.SmsBumpListId': number;
  'Search.TrendingLinks': Link[];
  'Search.HelpLinks': LinkWithIcon[];
  'Cart.PaymentMethods': string[];
  'Returns.Preamble': string;
  'Returns.ReturnRequirements': string;
  'Returns.Afterpay'?: string;
  'Returns.ProcessingReturns': string;
  'Returns.StoreCredit'?: string;
  'Returns.FaultyItems': string;
  'SizeGuide.SizeGuideMessage': string;
  'SizeGuide.ClothingSizes': string[][];
  'SizeGuide.SizeConversions': string[][];
  'SizeGuide.ShoeSizes': string[][];
  'FAQ.MenuMappings': FAQMenuMapping[];
  'FAQ.PopularQuestions.Collaboration'?: string;
  'FAQ.PopularQuestions.PhysicalStore'?: string;
  'FAQ.PopularQuestions.StoreCredit'?: string;
  'FAQ.PopularQuestions.ShippingSchedule'?: string;
  'FAQ.PopularQuestions.MissingConfirmationEmail'?: string;
  'FAQ.PopularQuestions.BusinessHours'?: string;
  'FAQ.PopularQuestions.StoreLocation'?: string;
  'FAQ.PopularQuestions.ReturnAddress'?: string;
  'FAQ.Delivery.AustralianDelivery'?: string;
  'FAQ.Delivery.UKDelivery'?: string;
  'FAQ.Delivery.USADelivery'?: string;
  'FAQ.Delivery.Customs'?: string;
  'FAQ.Delivery.USADomesticOrders'?: string;
  'FAQ.Delivery.AusPostInstructions'?: string;
  'FAQ.Delivery.InternationalDelivery'?: string;
  'FAQ.Delivery.MissingOrder'?: string;
  'FAQ.Delivery.UPSDeliveryUSA'?: string;
  'FAQ.Delivery.CollectionCard'?: string;
  'FAQ.Delivery.CanadianOrders'?: string;
  'FAQ.Delivery.OvernightUSShipping'?: string;
  'FAQ.Delivery.SameDayDelivery'?: string;
  'FAQ.Delivery.Tracking'?: string;
  'FAQ.OrderIssues.GiftVouchersCancel'?: string;
  'FAQ.OrderIssues.OrderCancel'?: string;
  'FAQ.OrderIssues.OrderChange'?: string;
  'FAQ.OrderIssues.Customs'?: string;
  'FAQ.OrderIssues.IncorrectItem'?: string;
  'FAQ.OrderIssues.MissingItem'?: string;
  'FAQ.OrderIssues.OrderVerification'?: string;
  'FAQ.OrderIssues.OutOfStock'?: string;
  'FAQ.OrderIssues.FaultyItem'?: string;
  'FAQ.OrderIssues.PriceDifferenceRefund'?: string;
  'FAQ.OrderIssues.ScamAndFraud'?: string;
  'FAQ.PaymentsAndPromos.PaymentsAndPromos'?: string;
  'FAQ.PaymentsAndPromos.CurrencyConversion'?: string;
  'FAQ.PaymentsAndPromos.NzGst'?: string;
  'FAQ.PaymentsAndPromos.UsStateSalesTax'?: string;
  'FAQ.PaymentsAndPromos.Ordering'?: string;
  'FAQ.PaymentsAndPromos.OrderPayment'?: string;
  'FAQ.PaymentsAndPromos.InstallmentPayments'?: string;
  'FAQ.PaymentsAndPromos.StoreCreditPayments'?: string;
  'FAQ.PaymentsAndPromos.PayPalPayments'?: string;
  'FAQ.PaymentsAndPromos.PromoCodes'?: string;
  'FAQ.PaymentsAndPromos.PromoCodeNewCustomers'?: string;
  'FAQ.ReturnsAndRefunds.Exchanges'?: string;
  'FAQ.ReturnsAndRefunds.Returns'?: string;
  'FAQ.ReturnsAndRefunds.Refunds'?: string;
  'FAQ.ReturnsAndRefunds.AfterpayReturns'?: string;
  'FAQ.ReturnsAndRefunds.StoreCredit'?: string;
  'FAQ.ReturnsAndRefunds.Turnaround'?: string;
  'FAQ.Technical.ChangingCurrency'?: string;
  'FAQ.Technical.ChangingEmail'?: string;
  'FAQ.Technical.EmailNotAccepted'?: string;
  'FAQ.Technical.NotReceivingEmails'?: string;
  'FAQ.Technical.OrderConfirmationEmails'?: string;
  'FAQ.Technical.LoginProblems'?: string;
  'FAQ.Technical.Unsubscribe'?: string;
  'Contact.FAQLinks': ContactFAQLink[];
  'Contact.ContactVia': ContactViaItem[];
  'Page.Unidays.Icon': {
    icon: string;
    link: string;
    external: boolean;
  };
  'Page.Unidays.Image': Image;
  'Page.Unidays.Title': string;
  'Page.Unidays.Heading': string;
  'Page.Unidays.Body': string;
  'Page.Unidays.TermsAndConditions': LinkObject;
  'Page.Unidays.CTA': Array<LinkObject>;
  'Page.Unidays.Terms': string;
  'Page.Unidays.Conditions': string;
  'Page.Unidays.NoRegionTitle': string;
  'Page.Unidays.NoRegionBody': string;
};

const StaticContentContext = createContext<StaticContentType | null>(null);

export function StaticContentProvider({ children, value }: { children: ReactNode; value: StaticContentType }) {
  return <StaticContentContext.Provider value={value}>{children}</StaticContentContext.Provider>;
}

export function useStaticContent<K extends keyof StaticContentType>(id: K): StaticContentType[K] {
  const values = useContext(StaticContentContext);
  if (!values) throw new Error('Missing StaticContentProvider from the tree');
  return values[id];
}

export function StaticContent({ id, inline }: { id: keyof StaticContentType; inline?: boolean }) {
  const values = useContext(StaticContentContext);
  const value = values?.[id] || '';
  if (typeof value !== 'string') throw new Error('StaticContent can only render raw HTML string.');
  const Comp = inline ? 'span' : 'div';
  return <Comp dangerouslySetInnerHTML={{ __html: value }} />;
}

// Hook will be deprecated when a CMS is introduced.
export const useStaticHook = () => {
  const getStaticContent = (id: keyof StaticContentType) => {
    const values = useContext(StaticContentContext);
    if (!values) throw new Error('Missing StaticContentProvider from the tree');
    return values[id];
  }

  const getStaticContentObject = (prefix: string) => {
    const values = useContext(StaticContentContext);
    if (!values) throw new Error('Missing StaticContentProvider from the tree');

    const result: Partial<StaticContentType> = {};
    Object.keys(values).forEach((key) => {
      if (key.includes(prefix)) {
        (result as any)[key] = values[key as keyof StaticContentType];
      }
    });

    return result;
  };

  return { getStaticContent,getStaticContentObject };
}
