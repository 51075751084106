import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQReturnsAndRefunds = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.ReturnsAndRefunds.Exchanges', title: 'Exchanges' },
    { key: 'FAQ.ReturnsAndRefunds.Returns', title: 'Making a return' },
    { key: 'FAQ.ReturnsAndRefunds.Refunds', title: 'Refunds' },
    { key: 'FAQ.ReturnsAndRefunds.AfterpayReturns', title: 'Returning something purchased with AfterPay' },
    { key: 'FAQ.ReturnsAndRefunds.StoreCredit', title: 'Store Credit' },
    { key: 'FAQ.ReturnsAndRefunds.Turnaround', title: 'Turnaround time for returns' },
  ];

  return <FAQAccordion faqItems={faqItems} />;
};

export default FAQReturnsAndRefunds;