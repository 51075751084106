import React from 'react';
import { Sheet } from '@ui/components/core';
import { MetricSelector, Tabs } from '@ui/components/shared';
import { Button } from "@ui/components/core/button"
import env from '@ui/env';
import { Models } from '@ui/pages/SizeGuide/SizeGuide';
import { Product } from '@ui/types/models/Product';
import cn from '@ui/utils/cn';
import { unzip } from 'lodash';
import Image from '@ui/components/core/image';
import { useInView } from 'react-intersection-observer';
import { useStaticContent } from '@ui/providers/static-content-provider';

const AuClothingSizes = [
  ['SIZE (AUS)', 'BUST (CM)', 'WAIST (CM)', 'HIPS (CM)'],
  ['XXS (AU4)', '76', '58', '86'],
  ['XS (AU6)', '81', '63', '91'],
  ['S (AU8)', '86', '68', '96'],
  ['M (AU10)', '91', '73', '101'],
  ['L (AU12)', '96', '78', '106'],
  ['XL (AU14)', '101', '83', '111'],
  ['XXL (AU16)', '106', '88', '116'],
];

const UsClothingSizes = [
  ['SIZE (USA)', 'BUST (IN)', 'WAIST (IN)', 'HIPS (IN)'],
  ['XXS (US0)', '30', '23', '34'],
  ['XS (US2)', '32', '25', '36'],
  ['S (US4)', '34', '27', '38'],
  ['M (US6)', '36', '29', '40'],
  ['L (US8)', '38', '31', '42'],
  ['XL (US10)', '40', '33', '44'],
  ['XXL (US12)', '42', '35', '46'],
];

const AuShoeSizes = [
  ['LENGTH (CM)', 'AUS', 'US', 'UK', 'EU'],
  ['21.6', '4', '4', '2', '35'],
  ['22.0', '5', '5', '3', '36'],
  ['22.9', '6', '6', '4', '37'],
  ['23.7', '7', '7', '5', '38'],
  ['24.5', '8', '8', '6', '39'],
  ['25.5', '9', '9', '7', '40'],
  ['26.2', '10', '10', '8', '41'],
  ['27.1', '11', '11', '9', '42'],
];

const UsShoeSizes = [
  ['LENGTH (IN)', 'AUS', 'US', 'UK', 'EU'],
  ['8.50', '4', '4', '2', '35'],
  ['8.66', '5', '5', '3', '36'],
  ['9.02', '6', '6', '4', '37'],
  ['9.33', '7', '7', '5', '38'],
  ['9.65', '8', '8', '6', '39'],
  ['10.04', '9', '9', '7', '40'],
  ['10.31', '10', '10', '8', '41'],
  ['10.67', '11', '11', '9', '42'],
];

const Sizes: Record<string, string[][]> = {
  'clothes.cm': AuClothingSizes.slice(1),
  'clothes.in': UsClothingSizes.slice(1),
  'shoes.cm': AuShoeSizes.slice(1),
  'shoes.in': UsShoeSizes.slice(1),
};

const Measurements: Record<string, string[]> = {
  'clothes.cm': AuClothingSizes[0],
  'clothes.in': UsClothingSizes[0],
  'shoes.cm': AuShoeSizes[0],
  'shoes.in': UsShoeSizes[0],
};

const ModelMappings: Record<string, (typeof Models)[number]> = Models.reduce((prev, model) => {
  return { ...prev, [model.tag]: model };
}, {});

const DEFAULT_METRIC = env.NEXT_PUBLIC_REGION === 'US' ? 'in' : 'cm';

type Props = {
  product: Product;
  open: boolean;
  onClose: (open: boolean) => void;
};

type ContextValue = {
  product: Product;
  metric: string;
  setMetric: (metric: string) => void;
};

const Context = React.createContext<ContextValue>({} as ContextValue);

export default function SizeGuideSheet({ product, open, onClose }: Props) {
  const [metric, setMetric] = React.useState(DEFAULT_METRIC);

  React.useEffect(() => {
    if (open) return;
    setMetric(DEFAULT_METRIC);
  }, [open]);

  return (
    <Context.Provider
      value={{
        product,
        metric,
        setMetric,
      }}
    >
      <Sheet.Root open={open} onOpenChange={onClose}>
        <Sheet.Content
          side="dynamic"
          withCloseButton={false}
          className="isolate p-[--gutter] pt-0 [--gutter:1rem] h-[37.5rem] !max-h-[37.5rem] md:[--gutter:2rem] md:!max-h-none"
        >
          <StickyHeader />
          <div className="mt-4 flex items-center justify-between">
            <div className="uppercase font-semibold tracking-[0.004em]">{product.productType}</div>
            <MetricSelector value={metric} onChange={setMetric} />
          </div>
          <div className="mt-4">
            <Tabs.Root defaultValue={isClothing(product) ? 'model' : 'size'}>
              {isClothing(product) && (
                <Tabs.List className="w-full border-b">
                  <StyledTabsTrigger value="model">Model</StyledTabsTrigger>
                  <StyledTabsTrigger value="size">{getSizeTitle(product.productType)}</StyledTabsTrigger>
                  <StyledTabsTrigger value="conversion">Size Conversion</StyledTabsTrigger>
                </Tabs.List>
              )}
              <Tabs.Content className="mt-5" value="model">
                <ModelSection />
              </Tabs.Content>
              <Tabs.Content className="mt-5" value="size">
                {isClothing(product) && <HowToMeasureButton />}
                <SizeTable />
                <Disclaimer />
                {isClothing(product) && <HowToMeasureSection className="mt-8" />}
              </Tabs.Content>
              <Tabs.Content className="mt-5" value="conversion">
                <SizeConversionTable />
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </Sheet.Content>
      </Sheet.Root>
    </Context.Provider>
  );
}

function StickyHeader() {
  const { ref, entry } = useInView();
  const [sticky, setSticky] = React.useState(false);

  React.useEffect(() => {
    entry && setSticky(!entry.isIntersecting);
  }, [entry]);

  return (
    <>
      <div ref={ref} />
      <div
        className={cn(
          'sticky top-0 z-10 bg-white py-4 md:pt-6 flex items-center justify-between',
          sticky ? '-mx-[--gutter] px-[--gutter] shadow-[0px_8px_8px_0px_#0000001A]' : 'border-b border-[#B3B3B3]',
        )}
      >
        <Sheet.Title>Size Guide</Sheet.Title>
        <Sheet.Description className="sr-only">
          Hello Molly uses standard Australian sizes. Please refer to the conversion table below as a guide.
        </Sheet.Description>
        <Sheet.Close />
      </div>
    </>
  );
}

function StyledTabsTrigger(props: React.ComponentPropsWithoutRef<typeof Tabs.Trigger>) {
  return (
    <Tabs.Trigger
      {...props}
      className={cn(
        'flex-1 p-2.5 text-[14px] uppercase whitespace-nowrap relative',
        'before:absolute before:h-0.5 before:block before:bg-black before:opacity-0 before:-bottom-px before:inset-x-0',
        'data-[state=active]:font-semibold data-[state=active]:before:opacity-100',
      )}
    />
  );
}

function StyledTable(props: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <div className="overflow-auto">
      <table
        {...props}
        className={cn(
          'w-full',
          '[&_th]:text-[14px] [&_th]:text-center [&_th]:px-4 [&_th]:py-3 [&_th]:border [&_th]:border-[#E0E0E0] [&_th]:tracking-[0.004em] [&_th]:bg-[#FFF1F7] [&_th]:font-bold [&_th]:uppercase',
          '[&_td]:text-[14px] [&_td]:text-center [&_td]:px-4 [&_td]:py-3 [&_td]:border [&_td]:border-[#E0E0E0] [&_td]:tracking-[0.004em]',
          '[&_td:first-child]:font-semibold [&_td:first-child]:bg-[#FFF8FB] [&_td:first-child]:whitespace-nowrap',
        )}
      />
    </div>
  );
}

function SizeTable() {
  const { product, metric } = React.useContext(Context);

  const key = `${product.productType.toLowerCase()}.${metric}`;

  return (
    <StyledTable>
      <thead>
        <tr>
          {Measurements[key].map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Sizes[key].map((row) => (
          <tr key={row[0]}>
            {row.map((col) => (
              <td key={col}>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

function ModelSection() {
  const { product, metric } = React.useContext(Context);

  const model = product.tags.find((tag) => tag.startsWith('model_')) || '';

  return ModelMappings[model] ? (
    <div>
      <div className="flex items-center space-x-2">
        <div className="text-[14px] tracking-[0.004em] font-semibold uppercase">{ModelMappings[model].name}</div>
        <div className="text-[14px] tracking-[0.004em]">is a size {ModelMappings[model].dress_size}</div>
      </div>
      <Image
        className={cn('w-full max-w-[216px] md:max-w-[283px] mx-auto h-auto', metric === 'cm' ? 'block' : 'hidden')}
        src={ModelMappings[model].image_au}
        alt=""
        width={2183}
        height={3277}
        loading="eager"
      />
      <Image
        className={cn('w-full max-w-[216px] md:max-w-[283px] mx-auto h-auto', metric === 'in' ? 'block' : 'hidden')}
        src={ModelMappings[model].image_us}
        alt=""
        width={2183}
        height={3277}
        loading="eager"
      />
    </div>
  ) : (
    <div className="relative flex items-center justify-center p-6">
      <Image
        className="w-full max-w-[283px] mx-auto h-auto opacity-25"
        alt=""
        src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/MODEL_PLACEHOLDER.jpg?v=1724383827"
        width={1200}
        height={1200}
        loading="eager"
      />
    </div>
  );
}

function SizeConversionTable() {
  const sizeConversions = useStaticContent('SizeGuide.SizeConversions');

  return (
    <StyledTable>
      <thead>
        <tr>
          {unzip(sizeConversions)[0].map((col) => (
            <th key={col}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {unzip(sizeConversions)
          .slice(1)
          .map((row) => (
            <tr key={row[0]}>
              {row.map((col, i) => (
                <td key={i}>{col}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </StyledTable>
  );
}

function HowToMeasureButton() {
  return (
    <Button
      variant="unstyled"
      className="text-[14px] mb-5 no-underline inline md:hidden"
      onClick={() => {
        const element = document.getElementById('how-to-measure-section');
        element?.scrollIntoView();
      }}
    >
      Click <u>here</u> to learn how to measure yourself.
    </Button>
  );
}

function HowToMeasureSection({ className }: { className?: string }) {
  return (
    <div id="how-to-measure-section" className={className}>
      <div className="space-y-2">
        <div className="uppercase font-semibold tracking-[0.004em]">How to measure</div>
        <div className="text-[14px] tracking-[0.6px] leading-6">
          Ensure the measuring tape is level and not too tight to get an accurate measurement.
        </div>
      </div>
      <div className="mt-4 flex items-center">
        <div className="space-y-6 w-3/5">
          <div className="space-y-2">
            <div className="text-[14px] tracking-[0.6px] font-bold">A. BUST</div>
            <div className="text-[14px] tracking-[0.6px] leading-6">
              Measure around the fullest part of your bust, typically across the nipples
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-[14px] tracking-[0.6px] font-bold">B. HIPS</div>
            <div className="text-[14px] tracking-[0.6px] leading-6">
              Measure around the fullest part of your hips for accurate measurement.
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-[14px] tracking-[0.6px] font-bold">C. WAIST</div>
            <div className="text-[14px] tracking-[0.6px] leading-6">Measure around your natural waistline.</div>
          </div>
        </div>
        <div className="w-2/5">
          <Image
            src="https://cdn.shopify.com/s/files/1/0281/2071/1254/files/how-to-measure.png?v=1724374931"
            alt="how to measure sample"
            className="w-full h-auto"
            width={412}
            height={810}
          />
        </div>
      </div>
    </div>
  );
}

function Disclaimer() {
  return (
    <p className="text-xs tracking-[0.004em] mt-4">
      Our size guide is here to help you find your perfect fit! Please note actual measurements of garments may vary
      slightly due to the unique nature of each product&apos;s material and construction.
    </p>
  );
}

function getSizeTitle(type: string): string {
  return type === 'Clothes' ? 'Clothing Size' : `${type} Size`;
}

function isClothing(product: Product) {
  return product.productType === 'Clothes';
}
