import React, { useEffect } from 'react';
import WishlistProductCard from '@ui/components/shared/cards/WishlistProductCard/WishlistProductCard';
import { WishlistProduct } from '@ui/types/contextObjects';
import Link from 'next/link';
import { Button } from "@ui/components/core/button"
import { useAuthStore } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';
import { Drawer, DrawerBody, DrawerClose, DrawerContent, DrawerHeader } from '@ui/components/core/drawer';

type WishlistDrawerProps = {
  opened: boolean;
  onClose: () => void;
};

const WishlistDrawer = ({ opened, onClose }: WishlistDrawerProps): React.ReactElement => {
  const { wishlistStore } = wishlistSelectors;
  const customerId = useAuthStore((state) => state.customerId);
  const wishlist = wishlistStore.use.wishlist();
  const [isGuest, setIsGuest] = React.useState(true);
  const [wishlistItems, setWishlistItems] = React.useState<WishlistProduct[]>([]);

  useEffect(() => {
    if (!opened) return;
    if (customerId) setIsGuest(false);
    setWishlistItems(
      isWishlistFrontEnd(wishlist) ? Object.keys(wishlist.products).map((prod) => wishlist.products[prod]) : [],
    );
  }, [opened, wishlist, customerId]);

  function handleClose() {
    onClose();
  }

  return (
    <Drawer onClose={handleClose} open={opened} direction="right">
      <DrawerContent side="right" className="w-full sm:w-[28.125rem]">
        <DrawerHeader className="divide-y divide-black bg-white border-b border-black z-10 flex flex-col">
          <div className="flex justify-between items-center p-4 w-full">
            <div className="space-x-2 flex items-center">
              <div className="text-sm font-bold tracking-[0.01em]">MY WISHLIST</div>
              <div className="text-xs">
                {wishlistItems.length} {wishlistItems.length === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className="flex items-center">
              <DrawerClose onClick={handleClose} />
            </div>
          </div>

          {isGuest && (
            <div className="w-full px-4 py-6 min-h-[68px]">
              <div className="flex items-center">
                <p className="text-sm leading-[1.5] uppercase text-center">
                  {`DON'T LOSE YOUR FAVES! `}
                  <Link href="/account/login/" className="underline" onClick={onClose}>
                    SIGN IN
                  </Link>{' '}
                  TO SAVE YOUR WISHLIST NOW
                </p>
              </div>
            </div>
          )}
        </DrawerHeader>
        <DrawerBody className="px-4">
          {wishlistItems.length > 0 ? (
            <div className="py-0 divide-y-[0.6px] divide-black">
              {wishlistItems.map((wishlistItem) => (
                <div key={wishlistItem.handle} className="py-6">
                  <WishlistProductCard product={wishlistItem} onClose={onClose} />
                </div>
              ))}
            </div>
          ) : (
            <div className="py-6">
              <div className="text-[13px] uppercase text-center">Your wishlist is currently empty.</div>
              <div className="space-y-3 my-6">
                <Button className="w-full" asChild>
                  <Link href="/collections/dresses/">Shop Dresses</Link>
                </Button>
                <Button className="w-full" asChild>
                  <Link href="/collections/new/">Shop New In</Link>
                </Button>
                <Button className="w-full" asChild>
                  <Link href="/products/gift-card">Shop Gift Cards</Link>
                </Button>
              </div>
            </div>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default WishlistDrawer;
