import React from 'react';
import { Grid, Group, Stack, Prose } from '@ui/components/core';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@ui/components/core/accordion';
import { deliverySuburb1, deliverySuburb2, deliverySuburb3 } from './FAQSydneySameDayDeliveryDictionary';

const FAQSydneySameDayDelivery = (): React.ReactElement => (
  <Accordion type="single" collapsible defaultValue='delivery-options'>
    <AccordionItem value="delivery-options" className="border-b-[0.6px] border-black last:border-b-0">
      <AccordionTrigger className="uppercase text-sm py-[26px] text-left">
        What are same-day delivery options in Sydney
      </AccordionTrigger>
      <AccordionContent>
        <Prose size="small">
          <p>
            We offer evening (6pm-10pm) delivery to select postcodes in the metro Sydney area Monday to Friday
            (excluding public holidays). See below for a full list of suburbs where you can access this service. If you
            are eligible it will appear as a shipping option at check out once you put in your address details.
          </p>
          <p>Please call us on 02 9017 0300 if you are experiencing technical difficulties.</p>
          <Grid gutter="xl">
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb1.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb2.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb3.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
          </Grid>
        </Prose>
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);

export default FAQSydneySameDayDelivery;