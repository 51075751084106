import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQDelivery = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.Delivery.AustralianDelivery', title: 'Australian delivery' },
    { key: 'FAQ.Delivery.UKDelivery', title: 'UK delivery' },
    { key: 'FAQ.Delivery.USADelivery', title: 'USA delivery' },
    { key: 'FAQ.Delivery.Customs', title: 'Customs' },
    { key: 'FAQ.Delivery.USADomesticOrders', title: 'Delivery instructions for USA domestic orders' },
    { key: 'FAQ.Delivery.AusPostInstructions', title: 'Delivery instructions for Australia Post' },
    { key: 'FAQ.Delivery.InternationalDelivery', title: 'International Delivery' },
    { key: 'FAQ.Delivery.MissingOrder', title: 'Missing Order' },
    { key: 'FAQ.Delivery.UPSDeliveryUSA', title: 'USA express delivery with UPS' },
    { key: 'FAQ.Delivery.CollectionCard', title: 'No collection card from Australia Post' },
    { key: 'FAQ.Delivery.CanadianOrders', title: 'Orders to Canada' },
    { key: 'FAQ.Delivery.OvernightUSShipping', title: 'Overnight shipping to US' },
    { key: 'FAQ.Delivery.SameDayDelivery', title: 'Same-day delivery' },
    { key: 'FAQ.Delivery.Tracking', title: 'Tracking information' },
  ];

  return <FAQAccordion faqItems={faqItems} />;
};

export default FAQDelivery;