export const FilterImageCarouselMappings = [
  {
    handle: "/collections/clothing",
    sectionTitle: 'Shop by Category',
    links: [
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/DRESSES_AU.jpg?v=1730686733',
        text: 'Dresses',
        href: '/collections/dresses',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/PLAYSUITS_AU.jpg?v=1730686733',
        text: 'Playsuits',
        href: '/collections/playsuits',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/TOPS_AU.jpg?v=1730686733',
        text: 'Tops',
        href: '/collections/tops',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/BOTTOMS_AU.jpg?v=1730686734',
        text: 'Bottoms',
        href: '/collections/bottoms',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SETS_AND_COORDS_AU.jpg?v=1730686733',
        text: 'Sets & Co-ords',
        href: '/collections/sets',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SWIM_AU.jpg?v=1730686733',
        text: 'Swim',
        href: '/collections/swim',
      },
    ],
  },
  {
    handle: "/cart",
    sectionTitle: 'Shop by Style',
    links: [
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/DRESSES_AU.jpg?v=1730686733',
        text: 'Dresses',
        href: '/collections/dresses',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/PLAYSUITS_AU.jpg?v=1730686733',
        text: 'Playsuits',
        href: '/collections/playsuits',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/TOPS_AU.jpg?v=1730686733',
        text: 'Tops',
        href: '/collections/tops',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/BOTTOMS_AU.jpg?v=1730686734',
        text: 'Bottoms',
        href: '/collections/bottoms',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SETS_AND_COORDS_AU.jpg?v=1730686733',
        text: 'Sets & Co-ords',
        href: '/collections/sets',
      },
      {
        image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SWIM_AU.jpg?v=1730686733',
        text: 'Swim',
        href: '/collections/swim',
      },
    ],
  }
];
