import React from 'react';
import FAQAccordion from '@ui/components/features/pages/FAQ/FAQAccordion/FAQAccordion';

const FAQPaymentsAndPromos = (): React.ReactElement => {
  const faqItems = [
    { key: 'FAQ.PaymentsAndPromos.PaymentsAndPromos', title: 'Charging your account' },
    { key: 'FAQ.PaymentsAndPromos.CurrencyConversion', title: 'Currency conversions' },
    { key: 'FAQ.PaymentsAndPromos.NzGst', title: 'New Zealand GST' },
    { key: 'FAQ.PaymentsAndPromos.UsStateSalesTax', title: 'USA State Sales Tax' },
    { key: 'FAQ.PaymentsAndPromos.Ordering', title: 'Ordering' },
    { key: 'FAQ.PaymentsAndPromos.OrderPayment', title: 'Paying For Your Order' },
    { key: 'FAQ.PaymentsAndPromos.InstallmentPayments', title: 'Paying in installments' },
    { key: 'FAQ.PaymentsAndPromos.StoreCreditPayments', title: 'Paying with store credit' },
    { key: 'FAQ.PaymentsAndPromos.PayPalPayments', title: 'PayPal payment and e-cheque delays' },
    { key: 'FAQ.PaymentsAndPromos.PromoCodes', title: 'Promo codes' },
    { key: 'FAQ.PaymentsAndPromos.PromoCodeNewCustomers', title: 'Promo codes for new customers' },
  ];

  return <FAQAccordion faqItems={faqItems} />;
};

export default FAQPaymentsAndPromos;