import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CART_BUYER_IDENTITY_UPDATE } from '../../documents/mutations/CartBuyerIdentityUpdate';
import type { CartBuyerIdentityUpdateMutationVariables } from '../../__generated__/graphql';

type CartBuyerIdentityUpdateArgs = CartBuyerIdentityUpdateMutationVariables & {
  buyerIP: string | undefined;
};

export async function CartBuyerIdentityUpdate({ cartId, buyerIdentity }: CartBuyerIdentityUpdateArgs) {
  const { data, error } = await RunMutation(CART_BUYER_IDENTITY_UPDATE, {
    cartId: cartId,
    buyerIdentity: buyerIdentity,
  });

  if (error) {
    return { data: undefined, error };
  }

  if (!data.cartBuyerIdentityUpdate?.cart) {
    return { data: undefined, error: { message: 'Cart not found' } };
  }

  const cart = {
    id: data.cartBuyerIdentityUpdate.cart.buyerIdentity.customer?.id,
    email: data.cartBuyerIdentityUpdate.cart.buyerIdentity.email,
    phone: data.cartBuyerIdentityUpdate.cart.buyerIdentity.phone,
  };

  return { cart, error };
}
