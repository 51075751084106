import RunQuery from '@client-shopify/gql/utils/RunQuery.ts';
import { GET_PRODUCT } from '../../documents/queries';
import {
  GetSimplifiedProductQuery,
  type GetProductQuery,
  type GetProductQueryVariables,
} from '../../__generated__/graphql';
import { GET_SIMPLIFIED_PRODUCT } from '../../documents/queries/GetSimplifiedProduct.ts';

const CurrencyCode = {
  AUD: 'AUD',
  USD: 'USD',
  NZD: 'NZD',
  GBP: 'GBP',
} as const;

type Required<T> = Exclude<T, null | undefined>;
export type Product = Awaited<ReturnType<typeof GetProduct>>;
export type ProductProp = Required<Product>['product'];
export type SimplifiedProduct = Required<GetSimplifiedProductQuery['product']> & { middlewareId: string };

export async function GetProduct({ handle }: GetProductQueryVariables) {
  const { data, error } = await RunQuery<GetProductQuery>(GET_PRODUCT, { handle });

  if (!data || !data.product) return undefined;

  const siblings = await Promise.all(
    JSON.parse(data.product.siblings?.value || '[]').map((sibling: { id: string; handle: string }) =>
      RunQuery<GetSimplifiedProductQuery>(GET_SIMPLIFIED_PRODUCT, { handle: sibling.handle })
        .then((res) => (res.data?.product ? { middlewareId: sibling.id, ...res.data.product } : null))
        .catch(() => null),
    ),
  );
  const product = {
    id: data.product.id,
    handle: data.product.handle,
    title: data.product.title,
    vendor: data.product.vendor,
    productType: data.product.productType,
    availableForSale: data.product.availableForSale,
    tags: data.product.tags,
    description: data.product.description,
    descriptionHtml: data.product.descriptionHtml,
    siblings: Array.isArray(siblings) && (siblings.filter(Boolean) as Array<SimplifiedProduct>),
    styleWith: data.product.styleWith,
    price: data.product.priceRange.maxVariantPrice
      ? {
          amount: parseFloat(data.product.compareAtPriceRange.maxVariantPrice.amount).toFixed(2),
          currencyCode: CurrencyCode[data.product.priceRange.maxVariantPrice.currencyCode as keyof typeof CurrencyCode],
        }
      : {
          amount: 0,
          currencyCode: CurrencyCode['AUD'],
        },
    compareAtPrice: data?.product?.compareAtPriceRange.minVariantPrice
      ? {
          amount: parseFloat(data.product.priceRange.minVariantPrice.amount).toFixed(2),
          currencyCode: CurrencyCode[data.product.priceRange.minVariantPrice.currencyCode as keyof typeof CurrencyCode],
        }
      : {
          amount: 0,
          currencyCode: CurrencyCode['AUD'],
        },
    variants: data.product.variants.edges.map((productVariantEdge) => ({
      id: productVariantEdge.node.id,
      sku: productVariantEdge.node.sku ?? '',
      size: productVariantEdge.node.title ? productVariantEdge.node.title : 'None',
      price: productVariantEdge.node.price
        ? {
            amount: parseFloat(productVariantEdge.node.price.amount).toFixed(2),
            currencyCode: CurrencyCode[productVariantEdge.node.price.currencyCode as keyof typeof CurrencyCode],
          }
        : {
            amount: parseFloat('0').toFixed(2),
            currencyCode: CurrencyCode['AUD'],
          },
      compareAtPrice: productVariantEdge.node.compareAtPrice
        ? {
            amount: parseFloat(productVariantEdge.node.compareAtPrice.amount).toFixed(2),
            currencyCode:
              CurrencyCode[productVariantEdge.node.compareAtPrice.currencyCode as keyof typeof CurrencyCode],
          }
        : null,
      bnplPrice: {
        amount: (parseFloat(productVariantEdge.node.price.amount) / 4).toFixed(2),
        currencyCode: CurrencyCode[productVariantEdge.node.price.currencyCode as keyof typeof CurrencyCode],
      },
      quantityAvailable: productVariantEdge.node.quantityAvailable
        ? productVariantEdge.node.quantityAvailable.toString()
        : '0',
      availableForSale: productVariantEdge.node.availableForSale,
    })),
    images: data.product.images.edges.map((productImageEdge) => ({
      id: productImageEdge.node.id,
      url: productImageEdge.node.url,
      altText: productImageEdge.node.altText,
    })),
  };
  if (error) {
    console.error(error);
  }
  return { product, error };
}
