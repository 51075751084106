import React from 'react';

const CloseIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12617 12.8736C3.2944 13.0419 3.56716 13.0419 3.73539 12.8736L7.99984 8.60917L12.2645 12.8738C12.4327 13.0421 12.7055 13.0421 12.8737 12.8738C13.0419 12.7056 13.0419 12.4328 12.8737 12.2646L8.60906 7.99996L12.8736 3.73539C13.0419 3.56716 13.0419 3.2944 12.8736 3.12617C12.7054 2.95794 12.4326 2.95794 12.2644 3.12617L7.99984 7.39074L3.73547 3.12637C3.56724 2.95814 3.29449 2.95814 3.12626 3.12637C2.95803 3.2946 2.95803 3.56736 3.12626 3.73559L7.39063 7.99996L3.12617 12.2644C2.95794 12.4326 2.95794 12.7054 3.12617 12.8736Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
