import { RulerIcon, Sheet } from '@ui/components/core';
import { Button } from "@ui/components/core/button"
import SizeSelector from '../../selectors/SizeSelector/SizeSelector';
import React from 'react';
import SizeGuideSheet from '../SizeGuideSheet/SizeGuideSheet';
import WhereIsMySizeSheet from '../WhereIsMySizeSheet/WhereIsMySizeSheet';
import type { Product, Variant } from '@ui/types/models/Product';
import PetSizeGuideSheet from '../SizeGuideSheet/PetSizeGuideSheet';

export default function SizeSelectorSheet({
  product,
  open,
  onClose,
  onSelect,
  layout = 'flex',
}: {
  product: Product;
  open: boolean;
  onClose: () => void;
  onSelect: (variant: Variant) => void;
  layout?: 'grid' | 'flex';
}) {
  const [openSizeGuide, setOpenSizeGuide] = React.useState(false);
  const [openWhereIsMySize, setOpenWhereIsMySize] = React.useState(false);

  const outOfStockVariants =
    product.variants?.filter((v) => Number(v.quantityAvailable) < 1 || !v.availableForSale) || [];

  return (
    <>
      <Sheet.Root open={open && !openSizeGuide && !openWhereIsMySize} onOpenChange={onClose}>
        <Sheet.Content className="rounded-t-lg p-4 pb-12" side="bottom" withCloseButton={false}>
          <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3]">
            <Sheet.Title>Select size</Sheet.Title>
            <Sheet.Description className="sr-only">Please choose a size to add in your bag.</Sheet.Description>
            <Sheet.Close />
          </div>
          <div className="mt-5 space-y-3">
            {product.productType && product.productType !== 'Accessories' && (
              <div>
                <Button
                  variant="unstyled"
                  className="md:text-xs underline-offset-2"
                  onClick={() => setOpenSizeGuide(true)}
                >
                  <span>Size guide &amp; model size</span>
                  <RulerIcon className="size-4 ml-1" />
                </Button>
              </div>
            )}
            <SizeSelector
              layout={layout}
              product={product}
              value={undefined}
              setVariant={(variant) => {
                if (!variant) return;
                onSelect(variant);
                onClose();
              }}
            />
          </div>
          {outOfStockVariants.length > 0 && (
            <div className="mt-5">
              <Button
                variant="unstyled"
                className="md:text-xs underline-offset-2"
                onClick={() => setOpenWhereIsMySize(true)}
              >
                Where&apos;s my size? Notify me
              </Button>
            </div>
          )}
        </Sheet.Content>
      </Sheet.Root>

      {product.tags.includes('category_pets') ? (
        <PetSizeGuideSheet
          product={product}
          open={openSizeGuide}
          onClose={() => {
            setOpenSizeGuide(false);
          }}
        />
      ) : (
        <SizeGuideSheet
          product={product}
          open={openSizeGuide}
          onClose={() => {
            setOpenSizeGuide(false);
          }}
        />
      )}

      <WhereIsMySizeSheet
        product={product}
        open={openWhereIsMySize}
        onClose={() => {
          setOpenWhereIsMySize(false);
        }}
      />
    </>
  );
}
