/**
 * Props for the SwiperCarousel component.
 *
 * @property {React.ReactNode} children - The child elements to be rendered inside the carousel.
 * @property {React.RefObject<SwiperRef>} parentRef - A reference to the parent Swiper instance.
 * @property {(swiper: Swiper) => void} [onSlideChange] - Optional callback function that is called when the slide changes.
 * @property {(swiper: Swiper) => void} [onSlideInit] - Optional callback function that is called when the slide is initialized.
 * @property {Object} [buttons] - Optional object containing references to the previous and next buttons.
 * @property {React.RefObject<HTMLButtonElement>} buttons.prev - Reference to the previous button element.
 * @property {React.RefObject<HTMLButtonElement>} buttons.next - Reference to the next button element.
 *
 * You can parse all the params besides "on" for the swiper options as there are setup functions to allow the parsing of the index to an external state.
 * Reference to Swiper API here for the params: https://swiperjs.com/swiper-api#parameters
 */

/**
 * Props for the SwiperSlide component.
 *
 * @property {React.ReactNode} children - The child elements to be rendered inside the slide.
 * @property {boolean} [autoHeight=false] - Whether the slide should automatically adjust its height based on its content.
 * @property {string} [height="100%"] - The height of the slide.
 * @property {string} [maxHeight=""] - The maximum height of the slide.
 */

import { useEffect, useRef } from "react";
import type { Swiper } from 'swiper';
import type { SwiperOptions } from "swiper/types";

export type SwiperRef = HTMLElement & {
  swiper: Swiper;
  initialize: () => void;
};

export type SwiperCarouselProps = {
  children: React.ReactNode;
  parentRef: React.RefObject<SwiperRef>;
  onSlideChange?: (swiper: Swiper) => void;
  onSlideInit?: (swiper: Swiper) => void;
  buttons?: {
    prev: React.RefObject<HTMLButtonElement>;
    next: React.RefObject<HTMLButtonElement>;
  };
} & Omit<SwiperOptions, "on">;

type SwiperCarouselSlideProps = {
  id?: string;
  children: React.ReactNode;
  fitContent?: boolean;
  autoHeight?: boolean;
  height?: string;
  maxHeight?: string;
};

export const SwiperCarousel: React.FC<SwiperCarouselProps> = ({
  children,
  parentRef,
  onSlideInit,
  onSlideChange,
  buttons,
  ...rest
}) => {
  useEffect(() => {
    // pass component props to parameters
    const params = {
      lazy: true,
      on: {
        init: (swiper: Swiper) => {
          if (onSlideInit) {
            onSlideInit(swiper);
          }
          if (
            buttons &&
            buttons.prev &&
            buttons.next &&
            swiper.params.navigation &&
            swiper.params.navigation !== undefined &&
            typeof swiper.params.navigation !== 'boolean'
          ) {
            swiper.params.navigation.prevEl = buttons.prev.current;
            swiper.params.navigation.nextEl = buttons.next.current;
          }
          swiper.navigation.init();
          swiper.navigation.update();
        },
        slideChange: (swiper: Swiper) => {
          if (onSlideChange) {
            onSlideChange(swiper);
          }
        },
      },
      speed: 500,
      ...rest,
    };

    if (!parentRef.current) return;

    // Assign it to swiper element
    Object.assign(parentRef.current, params);

    // initialize swiper
    parentRef.current.initialize();

    return () => {
      if (parentRef.current?.swiper) {
        parentRef.current.swiper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <swiper-container
      init={false}
      ref={parentRef}
      style={{
        width: "100%",
        maxWidth: "100%",
        maxHeight: "100dvh",
        minHeight: 0,
        minWidth: 0,
        zIndex: 0,
      }}
    >
      {children}
    </swiper-container>
  );
};

export const SwiperSlide: React.FC<SwiperCarouselSlideProps> = (props) => {
  const {
    children,
    fitContent = false,
    autoHeight = false,
    height = "100%",
    maxHeight = "",
    id,
    ...rest
  } = props;


  return (
    <swiper-slide
      id={id}
      style={{
        height: autoHeight ? 'auto' : height,
        maxHeight: maxHeight,
        width: fitContent ? 'fit-content' : '',
      }}
      {...rest}
    >
      {children}
    </swiper-slide>
  );
};