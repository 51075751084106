import { OperationResult, cacheExchange, Client, fetchExchange } from '@urql/core';
import { DocumentNode, print } from 'graphql';
import fetch from 'cross-fetch';
import env from '../../env.ts';

const RunQuery = async <T = any>(
  query: DocumentNode,
  variables: object,
  buyerIP?: string,
  storeDomain?: string,
  accessToken?: string,
): Promise<OperationResult<T, object>> => {
  const endpoint = `https://${storeDomain || env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/${env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION}/graphql.json`;
  const token = accessToken || env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

  const graphQLClient = new Client({
    url: endpoint,
    requestPolicy: 'cache-and-network',
    exchanges: [cacheExchange, fetchExchange],
    fetch: fetch,
    fetchOptions: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
        'Shopify-Storefront-Buyer-IP': buyerIP !== undefined ? buyerIP : '',
      },
      body: JSON.stringify({ query: print(query), variables }),
    },
  });

  return await graphQLClient.query(query, variables).toPromise();
};

export default RunQuery;
