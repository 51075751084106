import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_ADDRESS_CREATE } from '../../documents/mutations';
import type {
  CustomerAddressCreateMutation,
  CustomerAddressCreateMutationVariables,
} from '../../__generated__/graphql';

export async function CustomerAddressCreate({
  customerAccessToken,
  address,
}: CustomerAddressCreateMutationVariables): Promise<{
  data: CustomerAddressCreateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_ADDRESS_CREATE, {
    customerAccessToken: customerAccessToken,
    address: address,
  });

  return { data, error };
}
