import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CART_LINES_ADD } from '../../documents/mutations';
import { CurrencyCode, CartLinesAddMutation, CartLinesAddMutationVariables } from '../../__generated__/graphql';
import { mapCartData } from '@client-shopify/gql/utils/MapCartData.ts';

export async function CartLinesAdd({ cartId, cartLinesAddInput }: CartLinesAddMutationVariables) {
  const output = {
    cartId,
    cartLinesAddInput,
  };

  const { data, error } = await RunMutation<CartLinesAddMutation>(CART_LINES_ADD, {
    cartId,
    cartLinesAddInput,
  });

  if (error) {
    return { cart: undefined, error };
  }

  if (!data?.cartLinesAdd?.cart) {
    return { cart: undefined, error: { message: 'Cart not found' } };
  }

  const cartData = data.cartLinesAdd?.cart;

  const cart = mapCartData(cartData);

  return { cart, error };
}
