import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { CompactSearchIcon, LeftChevronIcon } from '@ui/components/core';
import { Transition } from '@mantine/core';
import { getCookie, setCookie } from '@ui/hooks/useCookie';
import cn from '@ui/utils/cn';
import { debounce } from 'lodash';
import { default as getAutocomplete } from '@ui/axios/searchSpring/autocomplete';
import { useMediaQuery } from '@mantine/hooks';
import { Button } from "@ui/components/core/button"

type SearchFormProps = {
  isLoading?: boolean;
  searchString?: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  setSearchData: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  giftCardSearchCheck:(input:string,url:string) => string
  runSearchQuery: ({
    searchQuery,
    signal,
  }: {
    searchQuery: string;
    signal: AbortSignal;
  }) => ReturnType<typeof getAutocomplete>;
  handleResults: (searchQuery: string) => void;
};

const SearchForm = React.forwardRef(function SearchForm(
  { isLoading, searchString = '', onClose, handleResults,giftCardSearchCheck }: SearchFormProps,
  ref,
) {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = React.useState(searchString);
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [value, setValue] = React.useState('');
  const handleChange = useMemo(() => debounce(handleResults, 500), [handleResults]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (!searchQuery) return;
        const recentSearches = getCookie('recentSearches')
          .split(',')
          .filter((item) => item !== '')
          .toSpliced(7)
          .toString();
        setCookie('recentSearches', searchQuery + ',' + recentSearches);

        //Redirect logic if gift cards are searched up.
        const url = giftCardSearchCheck(searchQuery,`/search/products/${searchQuery}`)
   
        router.push(url);
        

        if (onClose) {
          onClose();
        }
      }}
    >
      <div className="flex items-center gap-4">
        <Button
          type="button"
          variant="unstyled"
          onClick={(e) => {
            e.preventDefault();
            if (onClose) {
              onClose();
            }
          }}
        >
          <LeftChevronIcon className="w-6 h-6" />
        </Button>
        <div className="border-[0.6px] border-black rounded-[6.25rem] bg-[#FAEAF0] overflow-hidden flex-1">
          <Transition
            duration={300}
            mounted
            timingFunction="ease-out"
            transition={{
              in: { transform: 'scale(1)', opacity: 1 },
              out: { transform: 'scale(0.8)', opacity: 0 },
              common: { transformOrigin: 'bottom right' },
              transitionProperty: 'transform,opacity',
            }}
          >
            {(styles) => (
              <div className="relative flex items-center" style={isDesktop ? styles : {}}>
                <div className="absolute left-[0.48rem] pointer-events-none flex items-center justify-center rounded-full w-5 h-5">
                  <CompactSearchIcon className="w-4 h-4 text-[#757575]" />
                </div>
                <input
                  ref={ref as React.MutableRefObject<HTMLInputElement>}
                  type="text"
                  className={cn(
                    'bg-transparent w-full h-8 text-base scale-[0.875] origin-left tracking-[0.03em] pl-9',
                    'placeholder:text-black',
                    'focus:outline-none',
                  )}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setSearchQuery(e.target.value);
                    handleChange(e.target.value);
                  }}
                  onFocus={(e) => {
                    let length = e.target.value.length;
                    e.target.setSelectionRange(length, length);
                  }}
                  autoFocus
                  data-autofocus
                  data-searchform
                />
                <div className="absolute right-[0.48rem] flex gap-[0.5625rem]">
                  <Button  
                    variant='unstyled'
                    type="button"
                    className={cn("underline text-xs ease-in duration-200",searchQuery ? 'opacity-1' : 'opacity-0 pointer-events-none')}
                    onClick={() => {
                      setSearchQuery('');
                      handleChange('');
                      setValue('');
                    }}>
                    Clear all
                  </Button>
                </div>
              </div>
            )}
          </Transition>
        </div>
        <Button type="submit" variant="unstyled" className=" no-underline text-sm" disabled={!searchQuery}>
          Search
        </Button>
      </div>
    </form>
  );
});

SearchForm.defaultProps = {
  onClose: undefined,
};

export default SearchForm;
