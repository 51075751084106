import { gql } from '../../__generated__/gql';

export const GET_ARTICLE = gql(`
  query getArticle($blogHandle: String!, $articleHandle: String!) {
    blog(handle: $blogHandle) {
      articleByHandle(handle: $articleHandle) {
        id
        handle
        title
        contentHtml
        content
        publishedAt
        image {
          url
          altText
        }
        seo {
          title
          description
        }
        tags
      }
    }
  }
`);
