import Link from 'next/link';
import Image from '@ui/components/core/image';
import { CatalogRoutes } from '@ui/types/routing/CatalogRoutingTable.type';
import { CatalogLabelsFeatured, CatalogImagesFeatured } from '@ui/types/mappings/CatalogMappings.type';

interface CatalogFeaturedGridProps {
  title: string;
  className?: string;
}

const CatalogFeaturedGrid = ({ title, ...props }: CatalogFeaturedGridProps) => (
  <div {...props}>
    <h5 className="uppercase font-semibold text-xl/6 pb-5">{title}</h5>
    <div className="grid grid-cols-2 gap-4 md:grid-cols-6 md:gap-6">
      {CatalogLabelsFeatured.map((collection, index) => (
        <Link
          key={collection + index}
          href={CatalogRoutes[collection.toUpperCase() as keyof typeof CatalogRoutes]}
          className="group"
        >
          <div className="relative w-full h-0 pb-[149.761%]">
            <Image
              src={CatalogImagesFeatured[collection.toUpperCase() as keyof typeof CatalogImagesFeatured]}
              alt={`${collection} featured image`}
            />
          </div>
          <p className="group-hover:underline text-center uppercase pt-3">Shop {collection}</p>
        </Link>
      ))}
    </div>
  </div>
);

export default CatalogFeaturedGrid;
