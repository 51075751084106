import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_CREATE } from '../../documents/mutations';
import type { CustomerCreateMutation, CustomerCreateMutationVariables } from '../../__generated__/graphql';

export async function CustomerCreate({ customerCreateInput }: CustomerCreateMutationVariables): Promise<{
  data: CustomerCreateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_CREATE, {
    customerCreateInput: customerCreateInput,
  });

  return { data, error };
}
