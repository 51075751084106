import RunMutation from '@client-shopify/gql/utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_ACTIVATE } from '../../documents/mutations';
import type { CustomerActivateMutation, CustomerActivateMutationVariables } from '../../__generated__/graphql';

export async function CustomerActivate({ customerId, input }: CustomerActivateMutationVariables): Promise<{
  data: CustomerActivateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_ACTIVATE, {
    customerId: customerId,
    input: input,
  });

  return { data, error };
}
