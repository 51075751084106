import type { MenuLink } from '@ui/providers/static-content-provider';
import cn from '@ui/utils/cn';
import { Container, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { groupBy } from 'lodash';
import Image from '@ui/components/core/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { parentCollectionSelectors } from '@ui/store/parentCollectionStore';

const MegaMenuPopover = ({ menuLink }: { menuLink: MenuLink }) => {
  const { parentCollectionStore } = parentCollectionSelectors;
  const [opened, { open, close }] = useDisclosure(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const router = useRouter();
  const setParentCollection = parentCollectionStore.use.setParentCollection();

  useEffect(() => {
    router.events.on('routeChangeStart', close);
    return () => router.events.off('routeChangeStart', close);
  }, [router.events, close]);

  const link = (
    <Link
      onMouseEnter={() => {
        timeoutRef.current = setTimeout(open, 300);
      }}
      onMouseLeave={() => {
        clearTimeout(timeoutRef.current);
        close();
      }}
      onClick={() => {
        close();
        setParentCollection(menuLink.label.toLowerCase());
      }}
      href={menuLink.href || '/'}
      className={cn(
        'flex py-2 items-center text-[14px] tracking-[0.015em]',
        'hover:underline hover:underline-offset-[6px]',
        ['Sale', '80% OFF SALE'].includes(menuLink.label) && 'text-[#B40000]',
      )}
    >
      {menuLink.label.toUpperCase()}
    </Link>
  );

  if (!menuLink.children) {
    return link;
  }

  const renderLinks = menuLink.children[0].children ? menuLink.children : [menuLink];

  return (
    <Popover opened={opened} width="100%" withinPortal offset={0} zIndex={1003} data-parent-collection={menuLink.label}>
      <Popover.Target>{link}</Popover.Target>

      <Popover.Dropdown
        onMouseEnter={open}
        onMouseLeave={close}
        className="hidden lg:block rounded-none border-0 p-0 border-b border-black"
      >
        <Container className="pt-5 pb-8">
          <div className={cn('grid gap-4', menuLink.label === 'Clothing' ? 'grid-cols-7' : 'grid-cols-6')}>
            {renderLinks.map((subLink) => (
              <ChildLinks
                key={subLink.label}
                links={subLink.children || []}
                label={subLink.label === menuLink.label ? undefined : subLink.label}
                parentLabel={menuLink.label}
              />
            ))}

            {menuLink.upsell && (
              <div className="col-span-2 col-end-7">
                <div className={cn("grid gap-8 pl-12",menuLink.upsell?.length === 1 ? "grid-cols-1" : "grid-cols-2")}>
                  {menuLink.upsell.map(({ imageUrl, productUrl, productTitle }) => (
                    <Link key={imageUrl} href={productUrl} className="block">
                      <div className={cn("relative", menuLink.upsell?.length === 1 ? "pb-[66.66%]" : "pb-[150%]")}>
                        <Image
                          src={imageUrl}
                          className="absolute w-full h-full object-cover"
                          alt={productTitle}
                          loading="eager"
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Container>
      </Popover.Dropdown>
    </Popover>
  );
};

type ChildLinksProp = {
  links: MenuLink[];
  label?: string;
  parentLabel: string;
};

const ChildLinks = ({ links, label, parentLabel }: ChildLinksProp) => {
  const { parentCollectionStore } = parentCollectionSelectors;
  const setParentCollection = parentCollectionStore.use.setParentCollection();

  return (
    <React.Fragment>
      {Object.values(groupBy(links, 'column')).map((groupedLinks, i) => (
        <div key={groupedLinks[0].label} className="space-y-4">
          {label && <div className="text-[13px] font-bold uppercase">{i === 0 ? label : <span>&nbsp;</span>}</div>}

          {groupedLinks.map((childLink) => (
            <div key={childLink.label}>
              <Link
                className="hover:bg-[#F3C5D7] transition text-[14px]"
                href={childLink.href || '/'}
                onClick={() => setParentCollection(parentLabel.toLowerCase())}
              >
                {childLink.label}
              </Link>
            </div>
          ))}
        </div>
      ))}
    </React.Fragment>
  );
};

export default MegaMenuPopover;
