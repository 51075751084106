import { GetCollection } from './GetCollection';
import env from '@ui/env';

export type ShopifyProducts = NonNullable<Awaited<ReturnType<typeof GetCollectionProducts>>>;

type CollectionResponse = NonNullable<Awaited<ReturnType<typeof GetCollection>>>;
type Product = NonNullable<CollectionResponse['data']['collection']>['products']['edges'][number];

export const GetCollectionProducts = async (collection: string) => {
  const getAllProducts = async (cursor?: string): Promise<Array<Product>> => {
    const { data } = await GetCollection({
      handle: collection,
      first: 250,
      after: cursor,
    });

    const edges = data?.collection?.products.edges ?? [];
    const hasNextPage = data?.collection?.products.pageInfo.hasNextPage;
    const endCursor = data?.collection?.products.pageInfo.endCursor;

    if (hasNextPage && endCursor) {
      const nextProducts = await getAllProducts(endCursor);
      return [...edges, ...nextProducts];
    }

    return edges;
  };

  const products = await getAllProducts();
  // filter siblings in products
  let copyProducts = [...products];
  products.forEach((product) => {
    const productNode = product.node;
    const metafieldValue = productNode.metafield?.value;
    if (metafieldValue) {
      const siblings = JSON.parse(metafieldValue);
      if (Array.isArray(siblings) && siblings.length > 0) {
        siblings.forEach((sibling, index) => {
          if (index > 0) {
            copyProducts = copyProducts.filter((product) => product.node.handle !== sibling.handle);
          }
        });
      }
    }
  });
  return copyProducts.map((product) => {
    const productNode = product.node;
    const sku = productNode.tags.find((tag) => tag.startsWith('sku-'))?.split('_')[1] ?? '';
    const shopifyId = productNode.id.split('/').pop() as string;
    const variants = productNode.variants.edges.map((edge) => {
      const node = edge.node;
      const variantShopifyId = node.id.split('/').pop() as string;
      return {
        id: variantShopifyId,
        size: node.title,
        available: String(node.availableForSale && node.quantityAvailable && node.quantityAvailable > 0),
        shopify_variant_id: variantShopifyId,
        sku: `sku-${node.title}`,
        url: `${env.NEXT_PUBLIC_BASE_URL}/products/${productNode.handle}?size=${node.title}`,
      };
    });
    return {
      price: productNode.priceRange.maxVariantPrice.amount as Number,
      currency: productNode.priceRange.maxVariantPrice.currencyCode,
      variant_compare_at_price: productNode.compareAtPriceRange.maxVariantPrice.amount as Number,
      images: productNode.images.edges.map((image) => image.node.url as string),
      imageUrl: (productNode.images.edges[0]?.node.url as string) || '',
      handle: productNode.handle,
      id: shopifyId,
      tags: productNode.tags,
      title: productNode.title,
      vendor: productNode.vendor,
      productType: productNode.productType,
      availableForSale: productNode.availableForSale,
      sku: sku,
      uid: shopifyId,
      ss_id: variants[0].id,
      mfield_categorisation_siblings: productNode.metafield?.value ?? null,
      ss_sizes: JSON.stringify(variants),
    };
  });
};
