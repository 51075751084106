import React from 'react';
import { useForm } from '@mantine/form';
import { FormHelperText, FormInput, FormLabel, FormSelect } from '../../../core';
import { Button } from "@ui/components/core/button"

import axios from 'axios';

interface MissingSizeRequestData {
  productVariantId: string;
  email: string;
}

interface MissingSizeResponse {
  // Define the expected properties of your response
  message: string;
}

const postMissingProductSize = async (data: MissingSizeRequestData): Promise<MissingSizeResponse> => {
  const url = '/api/middleware/missing-product';

  try {
    const response = await axios.post<MissingSizeResponse>(url, { data });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Handle server-side failure (e.g., 400 or 500 responses)
      throw new Error(`Server responded with status: ${error.response.status}`);
    } else {
      // Handle network or other errors not related to the HTTP response
      throw new Error('An error occurred while sending the request');
    }
  }
};

type OutOfStockFormProps = {
  onSuccess: () => void;
  sizeOptions: { label: string; value: string }[];
  initialSize?: string;
};

const OutOfStockForm = ({ onSuccess, sizeOptions, initialSize }: OutOfStockFormProps): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const { setFieldValue, ...form } = useForm({
    initialValues: {
      size: sizeOptions.length > 1 ? '' : sizeOptions[0].value,
      email: '',
    },
    validate: {
      size: (value) => (value ? null : 'Size is required'),
      email: (value) => {
        if (!value.trim().length) return 'Email is required';
        if (!/^\S+@\S+$/.test(value)) return 'Invalid email format';
        return null;
      },
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  React.useEffect(() => {
    setFieldValue('size', initialSize || '');
  }, [initialSize, setFieldValue]);

  return (
    <form
      noValidate
      onSubmit={form.onSubmit((values, event) => {
        setLoading(true);
        event.preventDefault();
        postMissingProductSize({
          productVariantId: values.size.split('/').pop() as string,
          email: values.email,
        })
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
        onSuccess();
      })}
    >
      <div className="space-y-4">
        <div>
          <FormLabel className="sr-only" htmlFor="outOfStockSize" required>
            Size
          </FormLabel>

          <FormSelect
            value={form.values.size}
            zIndex={1003}
            onChange={(newValue) => setFieldValue('size', newValue || '')}
            onBlur={() => form.validateField('size')}
            invalid={Boolean(form.errors.size)}
            placeholder="Select size"
            classNames={{
              input: '!text-[14px] placeholder:font-normal',
            }}
            data={sizeOptions}
            id="outOfStockSize"
            name="size"
            required
          />

          {form.errors.size && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.size}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="outOfStockEmail" required>
            Email
          </FormLabel>

          <FormInput
            {...form.getInputProps('email', { withError: false })}
            invalid={Boolean(form.errors.email)}
            autoComplete="email"
            id="outOfStockEmail"
            name="email"
            type="text"
            required
          />

          {form.errors.email && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.email}
            </FormHelperText>
          )}
        </div>
      </div>

      <Button type="submit" loading={loading} variant="primary" className="h-11 mt-4 text-[13px] w-full">
        Notify Me
      </Button>
    </form>
  );
};

export default OutOfStockForm;
