import { Button } from '@ui/components/core/button';
import cn from '@ui/utils/cn';

export default function MetricSelector({ value, onChange }: { value: string; onChange: (newValue: string) => void }) {
  return (
    <div className="inline-flex space-x-1 p-1 border rounded border-[#EEBFD4] bg-[#FCF3F6]">
      {['cm', 'in'].map((unit) => (
        <Button
          key={unit}
          variant="unstyled"
          onClick={() => onChange(unit)}
          data-status={unit === value ? 'on' : 'off'}
          className={cn(
            'px-2 py-1 rounded-sm inline-flex items-center text-xs border no-underline',
            'border-transparent bg-transparent hover:bg-white',
            'data-[status=on]:border-[#0D0005] data-[status=on]:bg-white',
          )}
        >
          {unit.toUpperCase()}
        </Button>
      ))}
    </div>
  );
}
