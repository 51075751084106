import env from '@client-shopify/env.ts';
import { CountryCode, CurrencyCode } from '../storefront/__generated__/graphql.ts';

export type CartPayload = {
  __typename?: 'Cart';
  id: string;
  checkoutUrl: any;
  updatedAt: any;
  createdAt: any;
  buyerIdentity: {
    __typename?: 'CartBuyerIdentity';
    countryCode?: CountryCode | null;
    email?: string | null;
    phone?: string | null;
    customer?: { __typename?: 'Customer'; id: string } | null;
  };
  lines: {
    __typename?: 'BaseCartLineConnection';
    edges: Array<{
      __typename?: 'BaseCartLineEdge';
      node:
        | {
            __typename?: 'CartLine';
            id: string;
            quantity: number;
            cost: {
              __typename?: 'CartLineCost';
              amountPerQuantity: {
                __typename?: 'MoneyV2';
                amount: any;
                currencyCode: CurrencyCode;
              };
            };
            attributes: Array<{
              __typename?: 'Attribute';
              key: string;
              value?: string | null;
            }>;
            merchandise: {
              __typename?: 'ProductVariant';
              id: string;
              title: string;
              availableForSale: boolean;
              quantityAvailable?: number | null;
              price: {
                __typename?: 'MoneyV2';
                amount: any;
                currencyCode: CurrencyCode;
              };
              product: {
                __typename?: 'Product';
                id: string;
                handle: string;
                title: string;
                vendor: string;
                availableForSale: boolean;
                tags: Array<string>;
                description: string;
                featuredImage?: {
                  __typename?: 'Image';
                  id?: string | null;
                  url: any;
                  altText?: string | null;
                } | null;
              };
            };
          }
        | {
            __typename?: 'ComponentizableCartLine';
            id: string;
            quantity: number;
            cost: {
              __typename?: 'CartLineCost';
              amountPerQuantity: {
                __typename?: 'MoneyV2';
                amount: any;
                currencyCode: CurrencyCode;
              };
            };
            attributes: Array<{
              __typename?: 'Attribute';
              key: string;
              value?: string | null;
            }>;
            merchandise: {
              __typename?: 'ProductVariant';
              id: string;
              title: string;
              availableForSale: boolean;
              quantityAvailable?: number | null;
              price: {
                __typename?: 'MoneyV2';
                amount: any;
                currencyCode: CurrencyCode;
              };
              product: {
                __typename?: 'Product';
                id: string;
                handle: string;
                title: string;
                vendor: string;
                availableForSale: boolean;
                tags: Array<string>;
                description: string;
                featuredImage?: {
                  __typename?: 'Image';
                  id?: string | null;
                  url: any;
                  altText?: string | null;
                } | null;
              };
            };
          };
    }>;
  };
  cost: {
    __typename?: 'CartCost';
    totalAmount: {
      __typename?: 'MoneyV2';
      amount: any;
      currencyCode: CurrencyCode;
    };
    subtotalAmount: {
      __typename?: 'MoneyV2';
      amount: any;
      currencyCode: CurrencyCode;
    };
  };
};

export const mapCartData = (cartData: CartPayload) => {
  return {
    id: cartData.id,
    checkoutUrl: cartData.checkoutUrl,
    cartLines: cartData.lines?.edges.map((cartLineEdge) => ({
      id: cartLineEdge.node.id,
      productId: cartLineEdge.node.merchandise.product.id,
      variantId: cartLineEdge.node.merchandise.id,
      title: cartLineEdge.node.merchandise.product.title,
      handle: cartLineEdge.node.merchandise.product.handle,
      tags: cartLineEdge.node.merchandise.product.tags,
      productAvailableForSale: cartLineEdge.node.merchandise.product.availableForSale,
      variantAvailableForSale: cartLineEdge.node.merchandise.availableForSale,
      featuredImage: {
        id: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.id
          : '',
        url: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.url
          : '',
        altText: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.altText
          : '',
      },
      size: cartLineEdge.node.merchandise.title,
      costPerQuantity: {
        amount: parseFloat(cartLineEdge.node.cost.amountPerQuantity.amount).toFixed(2),
        currencyCode:
          CurrencyCode[cartLineEdge.node.cost.amountPerQuantity.currencyCode as unknown as keyof typeof CurrencyCode],
      },
      quantity: cartLineEdge.node.quantity,
      quantityAvailable: cartLineEdge.node.merchandise.quantityAvailable,
      attributes: cartLineEdge.node.attributes,
    })),
    subtotalAmount: {
      amount: cartData.cost.subtotalAmount.amount,
      currencyCode: CurrencyCode[cartData.cost.subtotalAmount.currencyCode as unknown as keyof typeof CurrencyCode],
    },
    totalAmount: {
      amount: cartData.cost.totalAmount.amount,
      currencyCode: CurrencyCode[cartData.cost.totalAmount.currencyCode as unknown as keyof typeof CurrencyCode],
    },
    bnplAmount: {
      amount: (parseFloat(cartData.cost.subtotalAmount.amount) / 4).toFixed(2),
      currencyCode: CurrencyCode[cartData.cost.subtotalAmount.currencyCode as unknown as keyof typeof CurrencyCode],
    },
    shipping: {
      free: parseFloat(cartData.cost.subtotalAmount.amount) >= env.FREE_SHIPPING_AMOUNT,
      amountTillFree:
        parseFloat(cartData.cost.subtotalAmount.amount) < env.FREE_SHIPPING_AMOUNT
          ? (env.FREE_SHIPPING_AMOUNT - parseFloat(cartData.cost.subtotalAmount.amount)).toFixed(2)
          : '0.00',
    },
    updatedAt: cartData.updatedAt,
    createdAt: cartData.createdAt,
  };
};
