
import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

import cn from '@ui/utils/cn';
import { ScrollArea } from '@ui/components/core/scroll-area';
import CompactCloseIcon from './Icons/Common/CompactCloseIcon';

const Drawer = ({ shouldScaleBackground = true, ...props }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
	<DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
Drawer.displayName = 'Drawer';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Close>, 
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Close>>(({ className, ...props }, ref)=> (
	<DrawerPrimitive.Close ref={ref} className={cn("flex justify-center items-center size-7",className)} {...props}>
		<CompactCloseIcon height={16} width={16} />
	</DrawerPrimitive.Close>))

DrawerClose.displayName = DrawerPrimitive.Close.displayName;

const DrawerOverlay = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => <DrawerPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-[1002] bg-black/20', className)} {...props} />);
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

interface DrawerContentProps extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
  side?: 'left' | 'right';
  disableOverlay?: boolean;
  overlayClassName?: string;
  overlayFunction?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
	({ className,disableOverlay = false,overlayClassName, children, side="left",overlayFunction, ...props }, ref) => (
		<DrawerPortal>
			{!disableOverlay && <DrawerOverlay className={overlayClassName} onClick={overlayFunction} />}
			<DrawerPrimitive.Content
				ref={ref}
				className={cn('fixed inset-x-0 z-[1002] flex h-screen top-0 flex-col border bg-white max-h-[100dvh]', side === "right" ? "right-0 left-auto":"left-0 right-auto", className)}
				{...props}
			>
				{children}
			</DrawerPrimitive.Content>
		</DrawerPortal>
	),
);
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('flex justify-between items-center gap-1.5 text-center', className)} {...props} />
);
DrawerHeader.displayName = 'DrawerHeader';

type DrawerBodyProps = React.HTMLAttributes<HTMLDivElement> & {
  scrollArea?: boolean;
}
  
const DrawerBody = ({ className, children, scrollArea = true, ...props }: DrawerBodyProps) => scrollArea ? (
  <ScrollArea className={cn('flex-1 overflow-y-auto h-full', className)} {...props} dir="ltr" >
    {children}
  </ScrollArea> 
) : (children);

DrawerBody.displayName = 'DrawerBody';

const DrawerFooter = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2', className)} {...props} >
    {children}
  </div>
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>>(
	({ className, ...props }, ref) => (
		<DrawerPrimitive.Title ref={ref} className={cn('text-lg font-semibold leading-none tracking-tight', className)} {...props} />
	),
);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => <DrawerPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />);
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerBody, DrawerHeader, DrawerOverlay, DrawerPortal, DrawerTitle, DrawerTrigger };