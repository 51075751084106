import { useCallback, useEffect } from 'react';
import env from '@ui/env';
import { GetAllReviewsArgs, yotpoAuthResponse } from '@ui/types/apiRoutes/yotpo';

declare global {
  interface Window {
    wtba: any[];
    smsbump: any;
  }
}

const CUTOFF_DATE = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString();
const REVIEW_COUNT = 150;


type getReviewsArgs = Omit<GetAllReviewsArgs, 'utoken'>;

function useYotpoDataLayer() {
  useEffect(() => {
    if (!window) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'config',
      options: {
        store_id: env.NEXT_PUBLIC_YOTPO_APP_KEY,
      },
    });
  }, []);

  const yotpoCustomerIdentifcation = useCallback((customerEmail: string, customerPhone?: string) => {
    if (!window) return;
    if (!customerEmail) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'identify',
      phone: customerPhone ?? '',
      email: customerEmail,
    });
    window.wtba.push({
      type: 'home',
    });
    if (window.smsbump && typeof window.smsbump.track === 'function') {
      window.smsbump
        .identify({ phone: customerPhone ?? '' })
        .then(() => {
          console.log('Visitor identified by phone.');
        })
        .catch(() => {
          console.log('Could not identify by phone.');
        });

      window.smsbump
        .identify({ email: customerEmail ?? '' })
        .then(() => {
          console.log('Visitor identified by email.');
        })
        .catch(() => {
          console.log('Could not identify by email.');
        });
      window.smsbump.subscribe({
        phone: customerPhone,
      });
    }
  }, []);

  const yotpoProductView = useCallback((productId: string) => {
    if (!window) return;
    if (!productId) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'product',
      id: productId.split('/').at(-1),
    });
    // Ensure smsbump is available and properly initialized
    if (window.smsbump && typeof window.smsbump.track === 'function') {
      // Track the product view event
      window.smsbump
        .track('pageview')
        .then(() => {})
        .catch(() => {});
    } else {
      console.warn('smsbump is not available or not properly initialized');
    }
  }, []);

  const yotpoAddedToCart = useCallback((productId: string, quantity: number) => {
    if (!window) return;
    if (!productId) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'event',
      event: 'product_added_to_cart',
      id: productId.split('/').at(-1),
      quantity,
    });
  }, []);

  const getYotpoToken = useCallback(() => {
    return fetch('/api/yotpo/yotpoAuth')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch Yotpo token');
        }
        return response.json();
      })
      .then(token => {
        sessionStorage.setItem('yotpoToken', JSON.stringify(token));
        return token;
      })
      .catch(error => {
        console.error('Error in getYotpoToken:', error);
      });
  }, []);

  const getAllReviewsFunction = useCallback(async (args: getReviewsArgs = {
    count: REVIEW_COUNT,
    since_date: CUTOFF_DATE,
  }): Promise<any> => {
    return new Promise((resolve, reject) => {
      const sessionToken: string | null = sessionStorage.getItem('yotpoToken');
      let tokenPromise: Promise<yotpoAuthResponse>;
  
      if (!sessionToken) {
        tokenPromise = getYotpoToken();
      } else {
        tokenPromise = Promise.resolve(JSON.parse(sessionToken));
      }
  
      tokenPromise
        .then(token => {
          return fetch('/api/yotpo/getAllReviews', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ utoken: token.access_token, ...args }),
          });
        })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorDetails => {
              throw new Error(`Failed to fetch reviews: ${errorDetails.error}`);
            });
          }
          return response.json();
        })
        .then(reviews => {
          resolve(reviews);
        })
        .catch(error => {
          console.error('Error in getAllReviewsFunction:', error);
          reject(error);
        });
    });
  },[getYotpoToken])

  const getAllSiteReviewsFunction = useCallback(async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      fetch('/api/yotpo/getAllSiteReviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

      }).then(response => {
        if (!response.ok) {
          return response.json().then(errorDetails => {
            throw new Error(`Failed to fetch reviews: ${errorDetails.error}`);
          });
        }
        return response.json();
      })
      .then(reviews => {
        resolve(reviews.response);
      })
      .catch(error => {
        console.error('Error in getAllSiteReviewsFunction:', error);
        reject(error);
      });
    });
  },[])

  return { yotpoCustomerIdentifcation, yotpoProductView, yotpoAddedToCart,getAllReviewsFunction,getAllSiteReviewsFunction };
}

export default useYotpoDataLayer;
